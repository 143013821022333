import { css } from '@emotion/react';
import styled from '@emotion/styled';

const wrapperStyles = css`
    position: relative;
    width: 100%;
    display: flex;
    align-items: self-end;
    padding-bottom: 12px;
`;

export default styled('div')(wrapperStyles);
