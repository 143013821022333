import { css } from '@emotion/react';
import styled from '@emotion/styled';

import dt from '@constants/designTokens';

const compareTagStyles = css`
    padding: 4px 8px;
    border: 1px solid ${dt.Colors.Border.Primary};
    border-radius: 4px;
    text-align: center;
    font-weight: 700;
    color: ${dt.Colors.Typography.Main};
    margin-bottom: 4px;
    width: 70px;
`;

export default styled('div')(compareTagStyles);
