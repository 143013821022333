import { css } from '@emotion/react';
import styled from '@emotion/styled';

const contentStyles = css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 0;
    flex-grow: 1;
    min-height: 100vh;
    margin-left: 0;
    transition: margin 0.15s ease-in-out;
`;

const StyledContent = styled('div')(contentStyles);

export default StyledContent;
