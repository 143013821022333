import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import dt from '@constants/designTokens';

const getInputStyles = ({ error }) => css`
    font-size: 1rem;
    ${error &&
    css`
        border-color: ${dt.Colors.Border.Danger};
    `};
`;

export default styled(React.forwardRef((props, ref) => <input {...props} ref={ref} />))([getInputStyles]);
