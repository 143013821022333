import { css } from '@emotion/react';
import styled from '@emotion/styled';

const wrapperMapStyles = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 700px;
    height: 700px;
    z-index: -1;
`;

export default styled('div')(wrapperMapStyles);
