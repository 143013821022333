import typography from './typography';
import background from './background';
import border from './border';
import map from './map';

export default {
    Background: background,
    Border: border,

    Typography: typography,

    Map: map,
};
