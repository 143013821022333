import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Paragraph } from '@ui/index';

import dt from '@constants/designTokens';

const textStyles = css`
    position: relative;
    padding-left: 1.5rem;

    &:before {
        content: '';
        width: 1rem;
        height: 1rem;
        border-radius: 4px;
        background: ${dt.Colors.Background.Gray};
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    &:after {
        content: '';
        width: 1rem;
        height: 1rem;
        border-radius: 4px;
        background-color: ${dt.Colors.Background.Primary};
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        display: none;
    }
`;

export default styled(Paragraph)(textStyles);
