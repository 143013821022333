import React, { useMemo, memo } from 'react';
import { useMutation } from 'react-query';
import { useToggle } from '@uidotdev/usehooks';
import { useNavigate } from 'react-router-dom';

import { ROUTES_DYNAMIC } from '@/routes';
import { Button, ButtonVariant, Modal, Paragraph, TypographySize } from '@ui/index';

import { dayjs, convertWithSaveUTCTime } from '@functions/datetime';

import StyledButton from './styled/Button';
import StyledModalMessage from './styled/ModalMessage';

import { stopVoyage } from './api';

const WorkspaceTools = ({ voyage, onSetApiError }) => {
    const navigate = useNavigate();
    const [ isOpenStopModal, onChangeStateStopModal ] = useToggle(false);
    const { mutate: mutateStopVoyage, isLoading: isLoadingStopVoyage } = useMutation({
        mutationFn: () => {
            return stopVoyage(voyage.voyage_id);
        },
        onSuccess: () => navigate(ROUTES_DYNAMIC.VOYAGES_EDIT(voyage.voyage_id)),
        onError: (error) => onSetApiError(error),
    });

    const { eta } = voyage;

    const modalContentNode = useMemo(
        () => {
            return (
                <React.Fragment>
                    <StyledModalMessage variant={TypographySize.MEDIUM} className='mb-4'>
                        Are you sure to complete the voyage?
                    </StyledModalMessage>
                    <div className='d-flex justify-content-between'>
                        <StyledButton onClick={onChangeStateStopModal}>No, don't complete</StyledButton>
                        <StyledButton
                            variant={ButtonVariant.SUCCESS}
                            disabled={isLoadingStopVoyage}
                            onClick={() => {
                                mutateStopVoyage();
                                onChangeStateStopModal();
                            }}
                        >
                            Yes, complete
                        </StyledButton>
                    </div>
                </React.Fragment>
            );
        },
        [ isLoadingStopVoyage, mutateStopVoyage, onChangeStateStopModal ]
    );

    return (
        <div className='d-flex'>
            <div className='ms-auto d-flex align-items-center'>
                <Paragraph variant={TypographySize.LARGE}>
                    ETA: {eta ? dayjs(convertWithSaveUTCTime(eta)).format('DD.MM.YYYY HH:mm') : '-'}
                </Paragraph>
                <Button
                    variant={ButtonVariant.DANGER}
                    disabled={isLoadingStopVoyage}
                    className='ms-5'
                    onClick={onChangeStateStopModal}
                >
                    Complete voyage
                </Button>
            </div>

            <Modal title='End voyage' isOpen={isOpenStopModal} onClose={onChangeStateStopModal}>
                {modalContentNode}
            </Modal>
        </div>
    );
};

export default memo(WorkspaceTools);
