import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Paragraph } from '@ui/index';

const textStyles = css`
    margin-left: 1.5rem;
`;

export default styled(Paragraph)(textStyles);
