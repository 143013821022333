import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { TypographySize, TypographyTag } from '../constants';

const getParagraphStyles = ({ color, variant, as: tag, transform }) => {
    return css`
        margin: 0;
        padding: 0;
        color: ${color};
        text-transform: ${transform};

        ${variant === TypographySize.LARGE &&
        css`
            font-size: 1.15rem;
        `}
        ${variant === TypographySize.MEDIUM &&
        css`
            font-size: 1rem;
        `} ${variant === TypographySize.NORMAL &&
        css`
            font-size: 0.9rem;
        `} ${variant === TypographySize.SMALL &&
        css`
            font-size: 0.75rem;
        `};

        ${tag === TypographyTag.P &&
        css`
            & + & {
                margin-bottom: 1.5rem;
            }
        `};
    `;
};

export default styled('p')([getParagraphStyles]);
