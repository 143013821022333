import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Paragraph } from '@ui/index';

const titleStyles = css`
    position: absolute;
    bottom: 110%;
    left: calc(50% - 15px);
    transform: translateX(-50%);
    white-space: nowrap;
`;

export default styled(Paragraph)(titleStyles);
