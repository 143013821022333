import { css } from '@emotion/react';
import styled from '@emotion/styled';

const graphicsWrapperStyles = css`
    margin-left: auto;
    margin-top: 2rem;
    padding-left: 1.5rem;
`;

export default styled('div')(graphicsWrapperStyles);
