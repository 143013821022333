import { css } from '@emotion/react';
import styled from '@emotion/styled';

const wrapperStyles = css`
    border-radius: 10px;
    border: 1px solid black;
    padding: 1rem 1.4rem;
    display: flex;
    flex: 1;
    flex-direction: column;
`;

export default styled('div')(wrapperStyles);
