import React from 'react';
import classcat from 'classcat';
import styled from '@emotion/styled';

const Badge = ({ type, children }) => {
    const classes = classcat(['badge', { [`bg-${type}`]: type }]);
    return <div className={classes}>{children}</div>;
};

export default styled(Badge)();
