import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Paragraph } from '@ui/index';
import { TypographyWeight } from '@ui/index';

const detailsRowStyles = css`
    font-weight: ${TypographyWeight.MEDIUM};
    max-width: 10rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export default styled(Paragraph)(detailsRowStyles);
