import React, { memo } from 'react';

const Table = ({ theadNode, tbodyNode }) => {
    return (
        <table className='datatable-table'>
            {theadNode}
            {tbodyNode}
        </table>
    );
};

export default memo(Table);
