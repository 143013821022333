import { css } from '@emotion/react';
import styled from '@emotion/styled';

const endStyles = css`
    position: absolute;
    left: calc(100% - 3px);
    bottom: -4px;
`;

export default styled('div')(endStyles);
