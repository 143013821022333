import { css } from '@emotion/react';
import styled from '@emotion/styled';

const finalStyles = css`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export default styled('div')(finalStyles);
