import { css } from '@emotion/react';
import styled from '@emotion/styled';

import dt from '@constants/designTokens';

const arrowStyles = css`
    width: 100%;
    height: 4px;
    background-color: ${dt.Colors.Background.Black};
    position: relative;
    border-radius: 8px;

    &:before,
    &:after {
        content: '';
        position: absolute;
        width: 50px;
        height: 4px;
        right: -10px;
        background-color: ${dt.Colors.Background.Black};
        border-radius: 8px;
    }

    &:before {
        top: 6px;
        transform: rotate(-14deg);
    }

    &:after {
        top: -6px;
        transform: rotate(14deg);
    }
`;

export default styled('div')(arrowStyles);
