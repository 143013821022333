import React, { useState, useMemo, useCallback } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { ROUTES, ROUTES_DYNAMIC } from '@/routes';
import { ButtonVariant, Modal, TypographySize } from '@ui/index';
import { InputField, WithErrorMutationHandler } from '@components/index';
import { VoyagerImportFile as VoyagerImportFileModal } from '@modules/index';

import { STATUS } from '@constants/voyageStatus';
import MASK, { match } from '@functions/mask';
import { dismantleToUTCLocalFormat, DISPLAY_FORMAT } from '@functions/datetime';

import StyledButton from './styled/Button';
import StyledModalMessage from './styled/ModalMessage';

import { startVoyage, editParamsVoyage, updateVoyageRoute, deleteVoyage } from './api';

const FormParamsSchema = Yup.object().shape({
    date: Yup.string().required('Required'),
});

const STATUS_LABEL = {
    [STATUS.created]: 'Start',
};

const MODAL_ACTIONS = {
    START: 'START',
    EDIT_PARAMS: 'EDIT_PARAMS',
    EDIT_ROUTE: 'EDIT_ROUTE',
    DELETE: 'DELETE',
};

const VoyageEditTools = ({ voyage, onRefetchVoyage, onHandleAPIError }) => {
    const navigate = useNavigate();
    const [modalAction, setModalAction] = useState(null);
    const onCloseActionModal = useCallback(() => setModalAction(null), [setModalAction]);

    const { voyage_id: voyageID, status } = voyage;

    // Start Voyage
    const { mutate: mutateStartRoute, isLoading: isLoadingStartVoyage } = useMutation({
        mutationFn: () => {
            if (Object.is(STATUS.created, status)) {
                return startVoyage(voyageID);
            }
        },
        onSuccess: onRefetchVoyage,
        onError: onHandleAPIError,
    });

    // Delete Voyage
    const { mutate: mutateDeleteRoute, isLoading: isLoadingDeleteVoyage } = useMutation({
        mutationFn: () => {
            return deleteVoyage(voyageID);
        },
        onSuccess: () => navigate(ROUTES.VOYAGES_LIST),
        onError: onHandleAPIError,
    });

    // Edit Params
    const { mutate: mutateEditParams, isLoading: isLoadingEditParams } = useMutation({
        mutationFn: (params) => {
            return editParamsVoyage(voyageID, params);
        },
        onSuccess: () => {
            onRefetchVoyage();
            onCloseActionModal();
        },
        onError: (error) => {
            onHandleAPIError(error);
        },
    });

    // Update Voyage route
    const { mutate: mutateUpdateVoyageRoute } = useMutation({
        mutationFn: (binaryString) => {
            return updateVoyageRoute(voyageID, binaryString);
        },
        onSuccess: () => {
            onRefetchVoyage();
        },
        onError: onHandleAPIError,
    });

    const modalContentNode = useMemo(() => {
        if (Object.is(MODAL_ACTIONS.START, modalAction)) {
            return (
                <React.Fragment>
                    <StyledModalMessage variant={TypographySize.MEDIUM} className='mb-4'>
                        After the start of the voyage <br /> the status will be changed.
                    </StyledModalMessage>
                    <div className='d-flex justify-content-between'>
                        <StyledButton onClick={onCloseActionModal}>No, don't start</StyledButton>
                        <StyledButton
                            variant={ButtonVariant.SUCCESS}
                            disabled={isLoadingStartVoyage}
                            onClick={() => {
                                mutateStartRoute();
                                onCloseActionModal();
                            }}
                        >
                            Yes, start
                        </StyledButton>
                    </div>
                </React.Fragment>
            );
        }

        if (Object.is(MODAL_ACTIONS.DELETE, modalAction)) {
            return (
                <React.Fragment>
                    <StyledModalMessage variant={TypographySize.MEDIUM} className='mb-4'>
                        The deleted route will disappear from the guide <br />
                        and will no longer be displayed.
                    </StyledModalMessage>
                    <div className='d-flex justify-content-between'>
                        <StyledButton onClick={onCloseActionModal}>No, don't delete</StyledButton>
                        <StyledButton
                            variant={ButtonVariant.DANGER}
                            disabled={isLoadingDeleteVoyage}
                            onClick={() => {
                                mutateDeleteRoute();
                                onCloseActionModal();
                            }}
                        >
                            Yes, delete
                        </StyledButton>
                    </div>
                </React.Fragment>
            );
        }

        if (Object.is(MODAL_ACTIONS.EDIT_PARAMS, modalAction)) {
            return (
                <Formik
                    initialValues={{ eta: '' }}
                    validationSchema={FormParamsSchema}
                    onSubmit={({ date, time }) => {
                        let eta = dismantleToUTCLocalFormat(date);

                        if (match(MASK.TIME['HH:MM'])(time)) {
                            eta = dismantleToUTCLocalFormat(`${date}:${time}`, `${DISPLAY_FORMAT}:HH:mm`);
                        }

                        mutateEditParams({ eta, time });
                    }}
                >
                    {({ handleSubmit, handleChange, values, errors }) => (
                        <Form className='d-flex w-100 flex-column' onSubmit={handleSubmit}>
                            <div className='w-100 flex-fill mb-4'>
                                <Field name='date'>
                                    {({ field }) => (
                                        <InputField
                                            {...field}
                                            label='Date'
                                            mask={MASK.DATE['DD.MM.YYYY']}
                                            placeholder='DD.MM.YYYY'
                                            value={values.date}
                                            error={errors.date}
                                            onChange={handleChange}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className='w-100 flex-fill mb-4'>
                                <Field name='time'>
                                    {({ field }) => (
                                        <InputField
                                            {...field}
                                            label='Time'
                                            mask={MASK.TIME['HH:MM']}
                                            placeholder='HH:MM'
                                            value={values.time}
                                            onChange={handleChange}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <StyledButton variant={ButtonVariant.DANGER} onClick={onCloseActionModal}>
                                    Cancel
                                </StyledButton>
                                <StyledButton type='submit' disabled={isLoadingEditParams}>
                                    Update
                                </StyledButton>
                            </div>
                        </Form>
                    )}
                </Formik>
            );
        }

        if (Object.is(MODAL_ACTIONS.EDIT_ROUTE, modalAction)) {
            return (
                <VoyagerImportFileModal
                    isOpen={true}
                    onClose={onCloseActionModal}
                    actionByImport={mutateUpdateVoyageRoute}
                />
            );
        }

        return null;
    }, [
        modalAction,
        isLoadingDeleteVoyage,
        isLoadingStartVoyage,
        isLoadingEditParams,
        mutateDeleteRoute,
        mutateStartRoute,
        mutateEditParams,
        mutateUpdateVoyageRoute,
        onCloseActionModal,
    ]);

    const startOrWorkspaceButtonNode = useMemo(() => {
        if (Object.is(status, STATUS.completed)) {
            return null;
        }

        if (Object.is(status, STATUS.created)) {
            return (
                <StyledButton
                    variant={ButtonVariant.PRIMARY}
                    onClick={() => setModalAction(MODAL_ACTIONS.START)}
                    disabled={isLoadingStartVoyage}
                >
                    {STATUS_LABEL[status]}
                </StyledButton>
            );
        }

        return (
            <StyledButton
                variant={ButtonVariant.PRIMARY}
                onClick={() => navigate(ROUTES_DYNAMIC.CAPTAIN_WORKSPACE(voyageID))}
            >
                Workspace
            </StyledButton>
        );
    }, [voyageID, status, isLoadingStartVoyage, setModalAction, navigate]);

    const modalTitle = useMemo(() => {
        switch (modalAction) {
            case MODAL_ACTIONS.START:
                return 'Start the voyage?';
            case MODAL_ACTIONS.DELETE:
                return 'Delete the voyage?';
            case MODAL_ACTIONS.EDIT_PARAMS:
                return 'Input new parameters';
            case MODAL_ACTIONS.EDIT_ROUTE:
                return 'Update route';
            default:
                break;
        }
    }, [modalAction]);

    return (
        <React.Fragment>
            <div className='d-flex'>
                {startOrWorkspaceButtonNode}

                <div className='d-flex ms-auto'>
                    <StyledButton
                        className='me-4'
                        variant={ButtonVariant.INFO}
                        onClick={() => setModalAction(MODAL_ACTIONS.EDIT_PARAMS)}
                    >
                        Edit parameters
                    </StyledButton>
                    <StyledButton
                        className='me-4'
                        variant={ButtonVariant.INFO}
                        onClick={() => setModalAction(MODAL_ACTIONS.EDIT_ROUTE)}
                    >
                        Edit route
                    </StyledButton>
                    <StyledButton
                        variant={ButtonVariant.DANGER}
                        onClick={() => setModalAction(MODAL_ACTIONS.DELETE)}
                    >
                        Delete
                    </StyledButton>
                </div>
            </div>
            <Modal title={modalTitle} isOpen={modalAction} onClose={onCloseActionModal}>
                {modalContentNode}
            </Modal>
        </React.Fragment>
    );
};

export default WithErrorMutationHandler(VoyageEditTools);
