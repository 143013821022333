import { css } from '@emotion/react';
import styled from '@emotion/styled';

const azipodsWrapperStyles = css`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export default styled('div')(azipodsWrapperStyles);
