import React from 'react';

import StyledWrapper from './styled/Wrapper';
import StyledAction from './styled/Action';
import StyledBackdrop from './styled/Backdrop';

const Backdrop = ({ actionNode, children }) => {
    return (
        <StyledWrapper>
            <StyledAction>{actionNode}</StyledAction>
            <StyledBackdrop>{children}</StyledBackdrop>
        </StyledWrapper>
    );
};

export default Backdrop;
