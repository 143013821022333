import { css } from '@emotion/react';
import styled from '@emotion/styled';

const valueStyles = css`
    position: absolute;
    bottom: 45px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;

    & > * {
        margin-bottom: 0;
    }
`;

export default styled('div')(valueStyles);
