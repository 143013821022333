import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Button } from '@ui/index';

const buttonStyles = css`
    width: 145px;
`;

export default styled(Button)(buttonStyles);
