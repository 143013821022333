import { css } from '@emotion/react';
import styled from '@emotion/styled';

const contentStyles = css`
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    text-align: center;
`;

export default styled('div')(contentStyles);
