import React from 'react';
import { Link } from 'react-router-dom';

import { Modal, TextSize, ModalVariant } from '@ui/index';

import StyledWrapper from './styled/Wrapper';
import StyledCode from './styled/Code';
import StyledParagraph from './styled/Message';

const UserError = ({ code, message, onCloseError }) => {
    return (
        <Modal
            title='User error'
            isOpen={true}
            variant={ModalVariant.LARGE_SIZE}
            onClose={onCloseError ? onCloseError : null}
        >
            <StyledWrapper>
                <StyledCode variant={TextSize.EXTRA_LARGE}>{code}</StyledCode>
                <StyledParagraph variant={TextSize.LARGE}>{message}</StyledParagraph>

                <br />

                <Link to='/'>Home page</Link>
            </StyledWrapper>
        </Modal>
    );
};

export default UserError;
