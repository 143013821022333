import dt from '@constants/designTokens/index';

export const BlockTag = {
    P: 'p',
};

export const InlineTag = {
    SPAN: 'span',
    SMALL: 'small',
};

export const HeadingTag = {
    H1: 'h1',
    H2: 'h2',
    H3: 'h3',
    H4: 'h4',
    H5: 'h5',
    H6: 'h6',
};

export const TypographyWeight = {
    UNSET: 'unset',
    NORMAL: '400',
    MEDIUM: '500',
    BOLD: '700',
};

export const TextSize = {
    EXTRA_LARGE: 'extra_large',
    LARGE: 'large',
    MEDIUM: 'medium',
    NORMAL: 'normal',
    SMALL: 'small',
    ULTRA_SMALL: 'ultra_small',
};

export const TypographySize = {
    ...HeadingTag,
    ...TextSize,
};

export const TypographyTag = {
    ...BlockTag,
    ...InlineTag,
};

export const TypographyColor = {
    ...dt.Colors.Typography,
};

export const TypographyTransform = {
    UPPERCASE: 'uppercase',
    LOWERCASE: 'lowercase',
    CAPITALIZE: 'capitalize',
    NONE: 'none',
};
