import base from './base';

export default {
    /* Основной текст */
    Main: base.Black800,
    Main600: base.Black600,
    /* Второстепенная информация */
    Secondary: base.Gray600,

    Success: base.Green,
    Danger: base.Red,

    Primary: base.Blue,
    White: base.TrueWhite,
};
