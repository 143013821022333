import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { rgba } from 'polished';

import dt from '@constants/designTokens';

import StyledBackdrop from './Backdrop';
import StyledAction from './Action';

const wrapperStyles = css`
    position: relative;
    display: inline-flex;

    &:hover {
        ${StyledBackdrop} {
            background: ${rgba(dt.Colors.Background.Black, 0.26)};
        }

        ${StyledAction} {
            display: block;
        }
    }
`;

export default styled('div')(wrapperStyles);
