import { css } from '@emotion/react';
import styled from '@emotion/styled';

import FeatherIcon from 'feather-icons-react';

import { IconSize } from '../constants';

const getIconStyles = ({ sizevariant: size = IconSize.NORMAL }) => css`
    ${size === IconSize.LARGE_M &&
    css`
        width: 4rem;
        height: 4rem;
    `} ${size === IconSize.LARGE_S &&
    css`
        width: 2rem;
        height: 2rem;
    `} ${size === IconSize.MEDIUM &&
    css`
        width: 1.2rem;
        height: 1.2rem;
    `} ${size === IconSize.SMALL &&
    css`
        width: 0.75rem;
        height: 0.75rem;
    `};

    ${size === IconSize.AUTO &&
    css`
        width: 100%;
        height: 100%;
    `};
`;

export default styled(FeatherIcon)([getIconStyles]);
