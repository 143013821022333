import React, { useRef } from 'react';
import { Tooltip } from 'react-tooltip';
import uniqid from 'uniqid';

const TooltipComponent = ({ anchor, children, ...options }) => {
    const idRef = useRef(uniqid());

    return (
        <React.Fragment>
            <span id={idRef.current}>{anchor}</span>
            <Tooltip anchorSelect={`#${idRef.current}`} {...options}>
                {children}
            </Tooltip>
        </React.Fragment>
    );
};

export default TooltipComponent;
