import React, { useMemo, memo } from 'react';

import { dayjs, convertWithSaveUTCTime, isValid } from '@functions/datetime';

import { TypographyTransform, Header, HeadingTag } from '@ui/index';

import StyledWrapper from './styled/Wrapper';
import StyledTitle from './styled/Title';
import StyledContent from './styled/Content';
import StyledContentInner from './styled/content/Inner';
import StyledContentTitle from './styled/content/Title';
import StyledContentText from './styled/content/Text';

const VoyageInfo = ({ voyage }) => {
    const startNode = useMemo(
        () => {
            const { created_at: start } = voyage;

            return (
                <StyledContentInner>
                    <StyledContentTitle>Created At</StyledContentTitle>
                    <StyledContentText>
                        {dayjs(convertWithSaveUTCTime(start)).format('DD.MM.YYYY HH:mm')}
                    </StyledContentText>
                </StyledContentInner>
            );
        },
        [ voyage ]
    );

    const endNode = useMemo(
        () => {
            const { eta: end } = voyage;

            return (
                <StyledContentInner>
                    <StyledContentTitle>ETA</StyledContentTitle>
                    {isValid(end) ? (
                        <React.Fragment>
                            <StyledContentText>
                                {dayjs(convertWithSaveUTCTime(end)).format('DD.MM.YYYY HH:mm')}
                            </StyledContentText>
                        </React.Fragment>
                    ) : (
                        <StyledContentText>-</StyledContentText>
                    )}
                </StyledContentInner>
            );
        },
        [ voyage ]
    );

    const departureNode = useMemo(
        () => {
            const { departure } = voyage;

            return (
                <StyledContentInner>
                    <StyledContentTitle>Departure</StyledContentTitle>
                    <StyledContentText>{departure}</StyledContentText>
                </StyledContentInner>
            );
        },
        [ voyage ]
    );

    const arrivalNode = useMemo(
        () => {
            const { arrival } = voyage;

            return (
                <StyledContentInner>
                    <StyledContentTitle>Arrival</StyledContentTitle>
                    <StyledContentText>{arrival}</StyledContentText>
                </StyledContentInner>
            );
        },
        [ voyage ]
    );

    const statusNode = useMemo(
        () => {
            const { status } = voyage;

            return (
                <StyledContentInner>
                    <StyledContentTitle>Status</StyledContentTitle>
                    <StyledContentText>{status}</StyledContentText>
                </StyledContentInner>
            );
        },
        [ voyage ]
    );

    const consumptionNode = useMemo(
        () => {
            const { fuel_consumption: consumption } = voyage;

            return (
                <StyledContentInner>
                    <StyledContentTitle>Fuel consumption</StyledContentTitle>
                    <StyledContentText>{consumption ? `${consumption} tons` : '-'}</StyledContentText>
                </StyledContentInner>
            );
        },
        [ voyage ]
    );

    const emissionNode = useMemo(
        () => {
            const { gas_emission: emission } = voyage;

            return (
                <StyledContentInner>
                    <StyledContentTitle>Gas emission</StyledContentTitle>
                    <StyledContentText>{emission ? `${emission} tons` : '-'}</StyledContentText>
                </StyledContentInner>
            );
        },
        [ voyage ]
    );

    return (
        <StyledWrapper>
            <StyledTitle className='mb-4'>
                <Header tag={HeadingTag.H3} transform={TypographyTransform.UPPERCASE}>
                    {voyage.vessel.name}
                </Header>
                <Header tag={HeadingTag.H3}>{voyage.route_name}.</Header>
                <Header tag={HeadingTag.H3}>IMO-{voyage.vessel.imo}</Header>
            </StyledTitle>
            <StyledContent>
                {startNode}
                {endNode}
                {departureNode}
                {arrivalNode}
                {statusNode}
                {consumptionNode}
                {emissionNode}
            </StyledContent>
        </StyledWrapper>
    );
};

export default memo(VoyageInfo);
