import React from 'react';
import { useNavigate } from 'react-router-dom';

import { UnauthorizedLayout, AuthForm, PreviewRoutesMap } from '@modules/index';

import { ROUTES } from '@/routes';

import StyledWrapperMap from './styled/WrapperMap';
import StyledLogoWrapper from './styled/LogoWrapper';
import StyledLogo from './styled/Logo';

const Auth = () => {
    const navigate = useNavigate();
    const onSuccess = () => navigate(ROUTES.VOYAGES_LIST);

    return (
        <UnauthorizedLayout>
            <div className='container d-flex h-100 align-items-center justify-content-center'>
                <div className='d-flex flex-fill justify-content-center'>
                    <div className='col-6'>
                        <StyledLogoWrapper>
                            <StyledLogo />
                        </StyledLogoWrapper>
                        <div className='card'>
                            <div className='d-flex card-body justify-content-center'>
                                <AuthForm onSuccess={onSuccess} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <StyledWrapperMap>
                <PreviewRoutesMap width='100%' height='100%' />
            </StyledWrapperMap>
        </UnauthorizedLayout>
    );
};

export default Auth;
