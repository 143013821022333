export default {
    TrueBlack: '#000',
    Black600: '#1e242d',
    Black800: '#212832',

    Gray400: '#e0e5ec',
    Gray800: '#5d5d64',

    Red: '#e81500',
    Red600: '#c51200',
    Red800: '#ba1100',

    Green100: '#00cfd5',
    Green200: '#00b0b5',
    Green300: '#00a6aa',

    Green: '#00ac69',
    Green600: '#009259',
    Green800: '#008a54',

    Blue100: '#aad3df',
    Blue: '#0061f2',
    Blue600: '#0052ce',
    Blue800: '#004ec2',
    WhiteDark: '#f2efe9',
    White: '#fafafa',
    TrueWhite: '#fff',
    Transparent: 'transparent',
};
