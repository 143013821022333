import { css } from '@emotion/react';
import styled from '@emotion/styled';

const modalStyles = css`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
`;

export default styled('div')(modalStyles);
