import { css } from '@emotion/react';

import dt from '@constants/designTokens';

export const getLinkStyles = () => css`
    display: inline-block;
    vertical-align: middle;
    font-size: 0.9rem;
    font-weight: 400;

    text-align: inherit;
    white-space: nowrap;
    background-color: ${dt.Colors.Background.Transparent};
    padding: 0.25rem;
    color: ${dt.Colors.Typography.Main};

    &:hover {
        color: ${dt.Colors.Typography.Main600};
        background: ${dt.Colors.Background.Gray};
        text-decoration: none;
    }
`;
