import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { DIALOG_VARIANT } from '../constants';

const dialogStyles = ({ variant = DIALOG_VARIANT.SMALL_SIZE }) => css`
    position: relative;
    margin-right: auto;
    margin-left: auto;
    pointer-events: none;
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;

    ${variant === DIALOG_VARIANT.EXTRA_SMALL_SIZE &&
    css`
        width: 350px;
    `} ${variant === DIALOG_VARIANT.SMALL_SIZE &&
    css`
        width: 700px;
    `} ${variant === DIALOG_VARIANT.LARGE_SIZE &&
    css`
        width: 100%;
        height: 100%;
        margin-top: 0;
        margin-bottom: 0;
    `};
`;

export default styled('div')(dialogStyles);
