import React from 'react';

import { Nav } from '@components/index';
import { Sidebar } from '@modules/index';

import StyledContent from './styled/Content';

const UnauthorizedLayout = ({ children }) => {
    return <div className='container d-flex flex-column h-100'>{children}</div>;
};

const Layout = ({ children }) => {
    return (
        <React.Fragment>
            <Nav />
            <div className='d-flex position-relative'>
                <Sidebar />
                <StyledContent>
                    <main>{children}</main>
                </StyledContent>
            </div>
        </React.Fragment>
    );
};

export { UnauthorizedLayout, Layout };
