import { css } from '@emotion/react';
import styled from '@emotion/styled';

import dt from '@constants/designTokens';

const mapWrapperStyles = css`
    width: 100%;
    height: 800px;
    border: 1px solid ${dt.Colors.Border.Main};
    border-radius: 8px;
    margin-left: auto;
`;

export default styled('div')(mapWrapperStyles);
