import React, { memo } from 'react';

import { HeadingTag } from '@ui/index';

import StyledWrapper from './styled/Wrapper';

import Preview from './Preview';
import UserDetails from './Details';

const Header = ({ details }) => {
    return (
        <StyledWrapper tag={HeadingTag.H6} className='d-flex align-items-center'>
            <Preview src='/profile.png' className='me-3' />
            <UserDetails {...details} />
        </StyledWrapper>
    );
};

export default memo(Header);
