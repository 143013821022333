import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Paragraph } from '@ui/index';

const modalMessageStyles = css`
    text-align: center;
`;

export default styled(Paragraph)(modalMessageStyles);
