import request from '@api';

export function getVoyageOptimizedRoute (voyageID) {
    return request({
        method: 'get',
        url: `/voyages/${voyageID}/optimized-route`,
    });
}

export function getVesselLocation (vesselID) {
    return request({
        method: 'get',
        url: `/vessels/${vesselID}/location`,
    });
}

export function getVesselCourse (vesselID) {
    return request({
        method: 'get',
        url: `/vessels/${vesselID}/course`,
    });
}
