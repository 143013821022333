import React from 'react';
import { useToggle } from '@uidotdev/usehooks';

import { Details, Modal, Spinner, SpinnerSize } from '@ui/index';
import { Button, Checkbox, Tooltip } from '@ui/index';

import { Layout, UserDropdown } from '@modules/index';

const CheckModal = () => {
    const [ isOpen, setOpen ] = useToggle(false);
    return (
        <React.Fragment>
            <Button onClick={setOpen}>Checkmodal</Button>
            <Modal title='hello World' isOpen={isOpen} onClose={setOpen}>
                Hello world
            </Modal>
        </React.Fragment>
    );
};

const Components = () => {
    return (
        <Layout>
            <div className='container'>
                <Details title='Buttons' className='mb-4'>
                    <br />
                    <div className='row'>
                        <div className='col-3'>
                            <Button variant='primary'>Primary</Button>
                        </div>
                        <div className='col-3'>
                            <Button variant='success'>Success</Button>
                        </div>
                        <div className='col-3'>
                            <Button variant='danger'>Danger</Button>
                        </div>
                        <div className='col-3'>
                            <Button variant='info'>Info</Button>
                        </div>
                    </div>
                </Details>

                <Details open title='Dropdown' className='mb-4'>
                    <UserDropdown />
                </Details>

                <Details open title='Modal' className='mb-4'>
                    <br />
                    <CheckModal />
                </Details>

                <Details open title='Spinner' className='mb-4'>
                    <br />
                    <Spinner className='me-4' size={SpinnerSize.MIN} />
                    <Spinner className='me-4' size={SpinnerSize.STANDART} />
                    <Spinner className='me-4' size={SpinnerSize.MAX} />
                    <Spinner message='Loading...' size={SpinnerSize.MIN} />
                </Details>

                <Details open title='Checkbox' className='mb-4'>
                    <Checkbox label='Checkbox 1' />
                    <Checkbox label='Checkbox 2' />
                </Details>

                <Details open title='Tooltip' className='mb-4'>
                    <Tooltip anchor={<span>Tooltip</span>}>Hello</Tooltip>
                </Details>
            </div>
        </Layout>
    );
};

export default Components;
