import React, { useState, memo } from 'react';

import { RoutesMap, MapDescriptive } from '@components/index';
import { TYPE as ROUTE_TYPE } from '@constants/route';

const RoutesMapWithDescriptive = (props) => {
    const { vesselPoint, routes, ...restProps } = props;
    const [ visibleRoutesType, setVisibleRoutesState ] = useState(new Set(Object.keys(ROUTE_TYPE)));

    return (
        <React.Fragment>
            <MapDescriptive visibleRoutes={visibleRoutesType} onSetRoutesState={setVisibleRoutesState} />
            <RoutesMap
                vesselPoint={vesselPoint}
                routes={routes.filter(({ type: globalType }) => visibleRoutesType.has(globalType))}
                {...restProps}
            />
        </React.Fragment>
    );
};

export default memo(RoutesMapWithDescriptive);
