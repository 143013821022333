import { css } from '@emotion/react';
import styled from '@emotion/styled';

const headerStyles = css`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-top-left-radius: calc(0.5rem - 1px);
    border-top-right-radius: calc(0.5rem - 1px);
    height: 64px;
`;

export default styled('div')(headerStyles);
