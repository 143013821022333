import React from 'react';

import StyledWrapper from './styled/Wrapper';
import StyledSpinner from './styled/Spinner';

const Spinner = ({ message = '', size, className }) => {
    return (
        <StyledWrapper className={className}>
            <StyledSpinner size={size} />
            {message}
        </StyledWrapper>
    );
};

export default Spinner;
