import { css } from '@emotion/react';
import styled from '@emotion/styled';

const logoWrapperStyles = css`
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;
`;

export default styled('div')(logoWrapperStyles);
