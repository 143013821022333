import { css } from '@emotion/react';
import styled from '@emotion/styled';

const wrapperStyles = css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 30px 0 1rem;
`;

export default styled('div')(wrapperStyles);
