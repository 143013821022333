import React from 'react';

class ErrorBoundary extends React.Component {
    constructor (props) {
        super(props);
        this.state = { hasError: false };

        this.on500Error = this.on500Error.bind(this);
    }

    static getDerivedStateFromError () {
        // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
        return { hasError: true };
    }

    componentDidCatch (error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
        console.log({ error });
    }

    on500Error () {
        this.setState({ hasError: true });
    }

    render () {
        if (this.state.hasError) {
            console.log('error render');
            // Можно отрендерить запасной UI произвольного вида
            return <h1>Что-то пошло не так.</h1>;
        }

        return React.cloneElement(this.props.children, { on500Error: this.on500Error });
    }
}

export default ErrorBoundary;
