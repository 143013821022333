import request from '@api';

export function getVoyage (voyageID) {
    return request({
        method: 'get',
        url: `/voyages/${voyageID}`,
    });
}

export function getVoyageOriginalRoute (voyageID) {
    return request({
        method: 'get',
        url: `/voyages/${voyageID}/original-route`,
    });
}

export function getVoyagePlannedRoute (voyageID) {
    return request({
        method: 'get',
        url: `/voyages/${voyageID}/planned-route`,
    });
}

export function getVoyageOptimizedRoute (voyageID) {
    return request({
        method: 'get',
        url: `/voyages/${voyageID}/optimized-route`,
    });
}

export function getVesselLocation (vesselID) {
    return request({
        method: 'get',
        url: `/vessels/${vesselID}/location`,
    });
}

export function getVesselCourse (vesselID) {
    return request({
        method: 'get',
        url: `/vessels/${vesselID}/course`,
    });
}
