import React, { useRef, useMemo } from 'react';

import { Input, Label } from '@ui/index';
import MaskedInput from './MaskedInput';

import StyledError from './styled/Error';

const InputField = (props) => {
    const { label = '', mask, error, ...inputProps } = props;
    const inputRef = useRef(null);

    const labelNode = useMemo(() => {
        if (!label) {
            return null;
        }

        return <Label onClick={() => inputRef.current.focus()}>{label}</Label>;
    }, [label, inputRef]);

    const inputNode = useMemo(() => {
        if (mask) {
            return <MaskedInput ref={inputRef} mask={mask} error={error} {...inputProps} />;
        }

        return <Input ref={inputRef} error={error} {...inputProps} />;
    }, [inputRef, mask, inputProps, error]);

    return (
        <React.Fragment>
            {labelNode}
            {inputNode}
            {error && <StyledError>{error}</StyledError>}
        </React.Fragment>
    );
};

export default InputField;
