import { css } from '@emotion/react';
import styled from '@emotion/styled';

import dt from '@constants/designTokens';

import { VARIANT } from '../constants';
import * as styles from './variant/index';

const getButtonStyles = ({ variant }) => {
    switch (variant) {
        case VARIANT.LINK:
            return styles.getLinkStyles();
        case VARIANT.PRIMARY:
            return styles.getPrimaryStyles();
        case VARIANT.SUCCESS:
            return styles.getSuccessStyles();
        case VARIANT.INFO:
            return styles.getInfoStyles();
        case VARIANT.DANGER:
            return styles.getDangerStyles();
        default:
            break;
    }
};

const getBtnStyles = ({ disabled }) => css`
    white-space: nowrap;
    font-size: 1rem;

    &:disabled {
        background: ${dt.Colors.Background.Gray};
        border-color: ${dt.Colors.Background.Gray};
    }
`;

export default styled('button')([getButtonStyles, getBtnStyles]);
