import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Header } from '@ui/index';

const codeStyles = css`
    text-align: center;
`;

export default styled(Header)(codeStyles);
