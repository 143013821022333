import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { UserDetailItem } from '@components/index';

const userDetailItemStyles = css`
    width: 100%;
    border-radius: 0;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
`;

export default styled(UserDetailItem)(userDetailItemStyles);
