import React, { memo } from 'react';

const Details = ({ open, title, className, children }) => {
    return (
        <details open={open} className={className}>
            <summary>{title}</summary>
            {children}
        </details>
    );
};

export default memo(Details);
