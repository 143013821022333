import { useQueryClient } from 'react-query';
import { jwtDecode } from 'jwt-decode';
import Coolies from 'js-cookie';

import CONFIG from '@config';
import { ROUTES } from '@/routes';

const useAuth = () => {
    const queryClient = useQueryClient();
    // Получить текущий токен из хранилища
    // Декодировать токен
    // Попробовать Определить Роль пользователя
    // Если роль пользователя не определена, то возвращаем пустой массив с ролями
    const token = Coolies.get(CONFIG.TOKEN_NAME) || '';

    if (!token) {
        return [];
    }

    let decoded = null;

    try {
        decoded = jwtDecode(token);
    } catch (err) {
        Coolies.remove(CONFIG.TOKEN_NAME, { path: '/' });
        queryClient.removeQueries();
        window.location = ROUTES.AUTH;
    }

    return {
        roles: decoded.user_types,
        userID: decoded.user_id,
    };
};

export default useAuth;
