import React from 'react';

import StyledRow from './styled/DetailsRow';

import { TypographyTag, TypographySize } from '@ui/index';
import dt from '@constants/designTokens';

const UserDetails = ({ first_name, last_name, email }) => {
    return (
        <div>
            <StyledRow>{[first_name, last_name].filter(Boolean).join(' ')}</StyledRow>
            <StyledRow
                color={dt.Colors.Typography.Secondary}
                tag={TypographyTag.SMALL}
                variant={TypographySize.SMALL}
            >
                {email}
            </StyledRow>
        </div>
    );
};

export default UserDetails;
