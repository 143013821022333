import { css } from '@emotion/react';
import styled from '@emotion/styled';

const pointWrapperStyles = css`
    width: 28px;
    height: 28px;
    position: absolute;
    right: -50px;
    top: 50%;
    transform: translateY(-50%);
`;

export default styled('div')(pointWrapperStyles);
