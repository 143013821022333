import React from 'react';
import MaskedInput from 'react-text-mask';

import { Input } from '@ui/index';

export default React.forwardRef((props, ref) => {
    return (
        <MaskedInput
            guide={false}
            render={(textMaskRef, inputProps) => {
                return (
                    <Input
                        {...inputProps}
                        ref={(node) => {
                            textMaskRef(node); // Keep this so the component can still function
                            ref.current = node; // Copy the ref for yourself
                        }}
                    />
                );
            }}
            {...props}
        />
    );
});
