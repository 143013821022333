import React, { memo, useCallback } from 'react';

import { TYPE as ROUTE_TYPE } from '@constants/route';

import StyledWrapper from './styled/Wrapper';
import StyledCheckbox from './styled/Checkbox';

const MapDescriptive = ({ visibleRoutes, onSetRoutesState }) => {
    const onChange = useCallback(
        (routeType) => () => {
            visibleRoutes.has(routeType) ? visibleRoutes.delete(routeType) : visibleRoutes.add(routeType);

            onSetRoutesState(new Set(visibleRoutes.keys()));
        },
        [visibleRoutes, onSetRoutesState]
    );

    return (
        <StyledWrapper>
            <StyledCheckbox
                label='Planned Route'
                routeType={ROUTE_TYPE.PLANNED}
                value={visibleRoutes.has(ROUTE_TYPE.PLANNED)}
                onChange={onChange(ROUTE_TYPE.PLANNED)}
            />
            <StyledCheckbox
                label='Original Route'
                routeType={ROUTE_TYPE.ORIGINAL}
                value={visibleRoutes.has(ROUTE_TYPE.ORIGINAL)}
                onChange={onChange(ROUTE_TYPE.ORIGINAL)}
            />
            <StyledCheckbox
                label='Optimized Route'
                routeType={ROUTE_TYPE.OPTIMIZED}
                value={visibleRoutes.has(ROUTE_TYPE.OPTIMIZED)}
                onChange={onChange(ROUTE_TYPE.OPTIMIZED)}
            />
        </StyledWrapper>
    );
};

export default memo(MapDescriptive);
