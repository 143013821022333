import React, { useMemo, memo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useQueries } from 'react-query';
import { useToggle } from '@uidotdev/usehooks';

import {
    Layout,
    VoyageEditTools,
    VoyageInfo,
    PreviewRoutesMap,
    RoutesMapWithDescriptive,
    InfoGraphics,
} from '@modules/index';
import { CenteredSpinner, WithErrorMutationHandler, Error } from '@components/index';
import { Backdrop, Button, Modal, ModalVariant, SpinnerSize } from '@ui/index';

import { TYPE as ROUTE_TYPE } from '@constants/route';

import StyledGraphicsWrapper from './styled/StyledGraphicsWrapper';

import {
    getVoyage,
    getVoyageOriginalRoute,
    getVoyageOptimizedRoute,
    getVoyagePlannedRoute,
    getVesselLocation,
    getVesselCourse,
} from './api';

const VoyageEdit = ({ onActionByQuery401Status, onHandleAPIError }) => {
    const { voyageID } = useParams();
    const [ isOpenMapModal, toggleMapModal ] = useToggle(false);
    const [ voyage, originalRoute, plannedRoute, optimizedRoute ] = useQueries([
        {
            queryKey: `voyages/${voyageID}`,
            queryFn: () => getVoyage(voyageID),
            refetchInterval: false,
            refetchOnWindowFocus: false,
            onError: (...params) => {
                onActionByQuery401Status(...params);
                onHandleAPIError(...params);
            },
            retry: 2,
        },
        {
            queryKey: `voyages/${voyageID}/original-route`,
            queryFn: () => getVoyageOriginalRoute(voyageID),
            onError: (...params) => {
                onActionByQuery401Status(...params);
                onHandleAPIError(...params);
            },
            refetchInterval: false,
            refetchOnWindowFocus: false,
            retry: 2,
        },
        {
            queryKey: `voyages/${voyageID}/planned-route`,
            queryFn: () => getVoyagePlannedRoute(voyageID),
            onError: (...params) => {
                onActionByQuery401Status(...params);
                onHandleAPIError(...params);
            },
            refetchInterval: false,
            refetchOnWindowFocus: false,
            retry: 2,
        },
        {
            queryKey: `voyages/${voyageID}/optimized-route`,
            queryFn: () => getVoyageOptimizedRoute(voyageID),
            onError: (...params) => {
                onActionByQuery401Status(...params);
                onHandleAPIError(...params);
            },
            refetchInterval: false,
            refetchOnWindowFocus: false,
            retry: 2,
        },
    ]);

    const { data: { data: voyageData } = {}, isLoading: isLoadingVoyage, refetch: refetchVoyage } = voyage;
    const {
        data: { data: originalRouteGEOJson = {} } = {},
        isLoading: isLoadingOriginalRoute,
    } = originalRoute;
    const { data: { data: plannedRouteGEOJson = {} } = {}, isLoading: isLoadingPlannedRoute } = plannedRoute;
    const {
        data: { data: optimizedRouteGEOJson = {} } = {},
        isLoading: isLoadingOptimizedRoute,
    } = optimizedRoute;

    const { data: { data: vesselLocation } = {}, isLoading: isLoadingVessel } = useQuery({
        queryKey: `vessel/location/${Boolean(voyageData) ? voyageData.vessel.vessel_id : ''}`,
        queryFn: () => getVesselLocation(voyageData.vessel.vessel_id),
        enabled: Boolean(voyageData),
        retry: 2,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });

    const { data: { data: vesselCourse } = {}, isLoading: isLoadingVesselCourse } = useQuery({
        queryKey: `vessel/${Boolean(voyageData) ? voyageData.vessel.vessel_id : ''}/course`,
        queryFn: () => getVesselCourse(voyageData.vessel.vessel_id),
        enabled: Boolean(voyageData),
        retry: 2,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });

    const routes = useMemo(
        () => {
            return [
                {
                    type: ROUTE_TYPE.ORIGINAL,
                    route: originalRouteGEOJson,
                },
                {
                    type: ROUTE_TYPE.PLANNED,
                    route: plannedRouteGEOJson,
                },
                {
                    type: ROUTE_TYPE.OPTIMIZED,
                    route: optimizedRouteGEOJson,
                },
            ].filter(({ route: { features = [] } }) => features.length > 0);
        },
        [ originalRouteGEOJson, plannedRouteGEOJson, optimizedRouteGEOJson ]
    );

    if (
        isLoadingVoyage ||
        isLoadingOriginalRoute ||
        isLoadingPlannedRoute ||
        isLoadingOptimizedRoute ||
        isLoadingVessel ||
        isLoadingVesselCourse
    ) {
        return <CenteredSpinner message='Loading...' size={SpinnerSize.MAX} />;
    }

    if (!voyageData) {
        return <Error code='404' message='Voyage is not found' />;
    }

    return (
        <Layout>
            <div className='container-fluid'>
                <div className='mt-4 mb-4'>
                    <VoyageEditTools voyage={voyageData} onRefetchVoyage={refetchVoyage} />
                </div>
                <div className='d-flex gap-4'>
                    <Backdrop actionNode={<Button onClick={toggleMapModal}>Open full map</Button>}>
                        <PreviewRoutesMap
                            routes={routes}
                            vesselPoint={vesselLocation}
                            vesselCourse={vesselCourse}
                            width={'450px'}
                            height={'450px'}
                        />
                    </Backdrop>
                    <VoyageInfo voyage={voyageData} />
                </div>

                <StyledGraphicsWrapper>
                    <InfoGraphics voyageID={voyageID} />
                </StyledGraphicsWrapper>
            </div>
            <Modal variant={ModalVariant.LARGE_SIZE} isOpen={isOpenMapModal} onClose={toggleMapModal}>
                <RoutesMapWithDescriptive
                    routes={routes}
                    vesselPoint={vesselLocation}
                    vesselCourse={vesselCourse}
                    id='full-routes'
                />
            </Modal>
        </Layout>
    );
};

export default WithErrorMutationHandler(memo(VoyageEdit));
