import { css } from '@emotion/react';
import styled from '@emotion/styled';

import dt from '@constants/designTokens';

import { DropdownPosition } from '../constants';

const getContentStyles = ({ position }) => css`
    display: block;
    width: auto;
    min-width: 15rem;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(33, 40, 50, 0.15);

    position: absolute;
    top: calc(100% + 1rem);
    z-index: 1000;
    padding: 8px 0;
    margin: 0;
    text-align: left;
    background-color: ${dt.Colors.Background.White};
    background-clip: padding-box;
    border-radius: 0.35rem;

    ${position === DropdownPosition.LEFT &&
    css`
        right: 0;
        left: auto;
    `};

    ${position === DropdownPosition.RIGHT &&
    css`
        left: 0;
        right: auto;
    `};

    ${position === DropdownPosition.CENTER &&
    css`
        right: 50%;
        transform: translateX(50%);
    `};
`;

export default styled('div')([getContentStyles]);
