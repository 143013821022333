import React, { memo, useCallback, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { Button, ButtonVariant, Header } from '@ui/index';
import { LinearGraphics, CenteredSpinner } from '@components/index';

import dt from '@constants/designTokens';

import StyledGraphicWrapper from './styled/GraphicWrapper';

import { getEmission, getPower, getSpeed } from './api';

const GRAPHIC_TYPE = {
    EMISSION: 'EMISSION',
    POWER: 'POWER',
    SPEED: 'SPEED',
};

const GRAPHIC_LABEL = {
    [GRAPHIC_TYPE.EMISSION]: 'Emission',
    [GRAPHIC_TYPE.POWER]: 'Power',
    [GRAPHIC_TYPE.SPEED]: 'Speed',
};

const GRAPHIC = {
    [GRAPHIC_TYPE.EMISSION]: {
        requestOptions: (...args) => ({
            queryFn: () => getEmission(args),
            queryKey: `dashboard-emission-${Array.from(args).toString()}`,
        }),
    },

    [GRAPHIC_TYPE.POWER]: {
        requestOptions: (...args) => ({
            queryFn: () => getPower(args),
            queryKey: `dashboard-power-${Array.from(args).toString()}`,
        }),
    },

    [GRAPHIC_TYPE.SPEED]: {
        requestOptions: (...args) => ({
            queryFn: () => getSpeed(args),
            queryKey: `dashboard-speed-${Array.from(args).toString()}`,
        }),
    },
};

const getGraphicNestedData = (graphicData, type) => {
    switch (type) {
        case GRAPHIC_TYPE.POWER:
            return [
                {
                    label: 'Original power',
                    points: graphicData.original_power || [],
                    options: {
                        series: {
                            fill: dt.Colors.Border.Primary,
                        },
                    },
                },
                {
                    label: 'Optimized power',
                    points: graphicData.optimized_power || [],
                    options: {
                        series: {
                            fill: dt.Colors.Border.Success,
                        },
                    },
                },
            ];

        case GRAPHIC_TYPE.SPEED:
            return [
                {
                    label: 'Original speed',
                    points: graphicData.original_speed || [],
                    options: {
                        series: {
                            fill: dt.Colors.Border.Primary,
                        },
                    },
                },
                {
                    label: 'Optimized speed',
                    points: graphicData.optimized_speed || [],
                    options: {
                        series: {
                            fill: dt.Colors.Border.Success,
                        },
                    },
                },
            ];

        case GRAPHIC_TYPE.EMISSION:
            return [
                {
                    label: 'Emission',
                    points: graphicData.voyage_gas_emission || [],
                    options: {
                        series: {
                            fill: dt.Colors.Border.Primary,
                        },
                    },
                },
            ];

        default:
            return null;
    }
};

const getIDByType = (type) => {
    switch (type) {
        case GRAPHIC_TYPE.POWER:
            return 'power-linear';

        case GRAPHIC_TYPE.SPEED:
            return 'speed-linear';

        case GRAPHIC_TYPE.EMISSION:
            return 'emission-linear';

        default:
            return null;
    }
};

const WithGraphicRequest = ({ voyageID, type, children }) => {
    const queryClient = useQueryClient();
    const graphic = GRAPHIC[GRAPHIC_TYPE[type]];
    const { queryKey, ...restRequestOptions } = graphic.requestOptions(voyageID);
    const { data: { data: graphicData = {} } = {}, isLoading } = useQuery({
        ...restRequestOptions,
        queryKey,
        enabled: !queryClient.getQueryCache().find(queryKey),
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });

    if (isLoading) {
        return (
            <StyledGraphicWrapper className='d-flex'>
                <CenteredSpinner message={`Loading ${GRAPHIC_LABEL[type]}`} />
            </StyledGraphicWrapper>
        );
    }

    const collectionLines = getGraphicNestedData(graphicData, type);

    if (collectionLines.every(({ points }) => points.length === 0)) {
        return (
            <StyledGraphicWrapper className='d-flex'>
                <div className='d-flex mx-auto mt-auto mb-auto'>
                    <Header>No data for graphic</Header>
                </div>
            </StyledGraphicWrapper>
        );
    }

    return React.cloneElement(children, {
        id: getIDByType(type),
        collectionLines,
    });
};

const InfoGraphics = ({ voyageID }) => {
    const [ type, setType ] = useState(GRAPHIC_TYPE.POWER);

    const renderGraphic = useCallback(
        () => {
            switch (type) {
                case GRAPHIC_TYPE.POWER:
                case GRAPHIC_TYPE.SPEED:
                case GRAPHIC_TYPE.EMISSION:
                    return <LinearGraphics.LineCollection />;

                default:
                    return null;
            }
        },
        [ type ]
    );

    const getButtonVariant = useCallback(
        (graphicType) => (type === graphicType ? ButtonVariant.SUCCESS : ButtonVariant.PRIMARY),
        [ type ]
    );

    return (
        <StyledGraphicWrapper>
            <div className='mt-4 mb-4 d-flex'>
                <div>
                    <Button
                        variant={getButtonVariant(GRAPHIC_TYPE.POWER)}
                        onClick={() => setType(GRAPHIC_TYPE.POWER)}
                    >
                        Power
                    </Button>
                </div>
                <div className='mx-4'>
                    <Button
                        variant={getButtonVariant(GRAPHIC_TYPE.SPEED)}
                        onClick={() => setType(GRAPHIC_TYPE.SPEED)}
                    >
                        Speed
                    </Button>
                </div>
                <div>
                    <Button
                        variant={getButtonVariant(GRAPHIC_TYPE.EMISSION)}
                        onClick={() => setType(GRAPHIC_TYPE.EMISSION)}
                    >
                        Emission
                    </Button>
                </div>
            </div>

            <h1>{GRAPHIC_LABEL[type]} graph:</h1>

            <WithGraphicRequest voyageID={voyageID} type={type}>
                {renderGraphic()}
            </WithGraphicRequest>
        </StyledGraphicWrapper>
    );
};

export default memo(InfoGraphics);
