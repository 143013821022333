import React, { useMemo, memo } from 'react';
import { useQueries } from 'react-query';
import * as am5map from '@amcharts/amcharts5/map';

import { CenteredSpinner } from '@components/index';
import { SpinnerSize } from '@ui/index';

import { RoutesMap } from '@components/index';
import { TYPE as ROUTE_TYPE } from '@constants/route';

import { getVoyageOptimizedRoute, getVesselLocation, getVesselCourse } from './api';

import StyledMapWrapper from './styled/MapWrapper';

const VesselOptimizedRoute = ({ voyageID, vesselID }) => {
    const [ optimizedRoute, location, course ] = useQueries([
        {
            queryKey: `voyages/${voyageID}/optimized-route`,
            queryFn: () => getVoyageOptimizedRoute(voyageID),
            refetchInterval: false,
            refetchOnWindowFocus: false,
        },
        {
            queryKey: `vessel/${vesselID}/location`,
            queryFn: () => getVesselLocation(vesselID),
            refetchInterval: false,
            refetchOnWindowFocus: false,
        },
        {
            queryKey: `vessel/${vesselID}/course`,
            queryFn: () => getVesselCourse(vesselID),
            refetchInterval: false,
            refetchOnWindowFocus: false,
        },
    ]);

    const {
        data: { data: optimizedRouteGEOJson = {} } = {},
        isLoading: isLoadingOptimizedRoute,
    } = optimizedRoute;
    const { data: { data: vesselLocation } = {}, isLoading: isLoadingVessel } = location;
    const { data: { data: vesselCourse = {} } = {}, isLoading: isLoadingVesselCourse } = course;

    const routes = useMemo(
        () => {
            return [
                {
                    type: ROUTE_TYPE.OPTIMIZED,
                    route: optimizedRouteGEOJson,
                },
            ].filter(({ route: { features = [] } }) => features.length > 0);
        },
        [ optimizedRouteGEOJson ]
    );

    const settings = useMemo(
        () => ({
            mapChart: {
                panX: 'translateX',
                panY: 'translateY',
                projection: am5map.geoMercator(),
                minZoomLevel: 3,
            },
        }),
        []
    );

    const initialZoomSettings = useMemo(
        () => ({
            zoom: 1,
            use3D: false,
        }),
        []
    );

    if (isLoadingOptimizedRoute || isLoadingVessel || isLoadingVesselCourse) {
        return (
            <StyledMapWrapper className='d-flex'>
                <CenteredSpinner message='Loading...' size={SpinnerSize.MAX} />
            </StyledMapWrapper>
        );
    }

    return (
        <StyledMapWrapper>
            <RoutesMap
                id='captain-worksplace-map'
                settings={settings}
                routes={routes}
                vesselPoint={vesselLocation}
                vesselCourse={vesselCourse}
                initialZoomSettings={initialZoomSettings}
            />
        </StyledMapWrapper>
    );
};

export default memo(VesselOptimizedRoute);
