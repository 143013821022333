import * as am5map from '@amcharts/amcharts5/map';
import am5geodata_worldHigh from '@amcharts/amcharts5-geodata/worldHigh';

import dt from '@constants/designTokens';

export const defaultSettings = {
    mapChart: {
        panX: 'rotateX',
        panY: 'rotateY',
        projection: am5map.geoOrthographic(),
        centerX: 0,
        centerY: 0,
        dx: 0,
        dy: 0,
    },
    mapPolygonSeries: {
        geoJSON: am5geodata_worldHigh,
        fill: dt.Colors.Map.landscape.land,
        stroke: dt.Colors.Map.borders,
    },
};
