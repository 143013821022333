import { css } from '@emotion/react';
import styled from '@emotion/styled';

const wrapperStyles = css`
    flex-basis: 20rem;
    flex-shrink: 0;
    transition: transform 0.15s ease-in-out;
    z-index: 1038;
    transform: translateX(0rem);
    position: absolute;
    left: 0;
    width: 300px;
    bottom: 0;
    top: 0;

    body.sidenav-toggled & {
        transform: translateX(-20rem);
    }
`;

export default styled('div')(wrapperStyles);
