import { css } from '@emotion/react';
import styled from '@emotion/styled';

import dt from '@constants/designTokens';

const getLineStyles = ({ lineColor = dt.Colors.Background.Black }) => css`
    width: 100%;
    height: 4px;
    background: ${lineColor};
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    width: 100%;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 20px;
        width: 4px;
        background: ${lineColor};
    }

    &:after {
        content: '';
        position: absolute;
        right: -15px;
        top: 50%;
        transform: translateY(-50%);
        height: 15px;
        width: 15px;
        border-radius: 50%;
        border: 2px solid ${dt.Colors.Border.Success};
    }
`;

export default styled('div')([getLineStyles]);
