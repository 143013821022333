import { css } from '@emotion/react';
import styled from '@emotion/styled';

import dt from '@constants/designTokens';

const pointStyles = css`
    background: ${dt.Colors.Background.Success};
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(45deg);
    z-index: 3;
`;

export default styled('div')(pointStyles);
