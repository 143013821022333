import { css } from '@emotion/react';
import styled from '@emotion/styled';

const wrapperStyles = css`
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default styled('div')(wrapperStyles);
