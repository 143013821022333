import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Paragraph } from '@ui/index';

const messageStyles = css`
    text-align: center;
    max-width: 90%;
`;

export default styled(Paragraph)(messageStyles);
