import { css } from '@emotion/react';
import styled from '@emotion/styled';

import dt from '@constants/designTokens';

const wrapperStyles = ({ isFocused, isDragAccept, isDragReject }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${dt.Colors.Border.Main};
    color: ${dt.Colors.Typography.Main};
    border-style: dashed;
    background-color: ${dt.Colors.Background.White};
    outline: none;
    transition: border 0.24s ease-in-out;
    height: 100%;

    ${isFocused &&
    css`
        border-color: ${dt.Colors.Border.Primary};
    `};

    ${isDragAccept &&
    css`
        border-color: ${dt.Colors.Border.Success};
    `};

    ${isDragReject &&
    css`
        border-color: ${dt.Colors.Border.Danger};
    `};
`;

export default styled('div')(wrapperStyles);
