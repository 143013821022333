import React, { memo } from 'react';

import { TypographyTag, TypographySize } from '@ui/index';

import StyledWrapper from './styled/Checkbox/Wrapper';
import StyledInput from './styled/Checkbox/Input';
import StyledText from './styled/Checkbox/Text';

const Checkbox = ({ label, value, onChange = () => {}, ...restProps }) => {
    return (
        <StyledWrapper {...restProps}>
            <StyledInput
                type='checkbox'
                value={value}
                checked={value}
                onChange={({ target: { value } }) => onChange(value)}
            />
            <StyledText tag={TypographyTag.SPAN} variant={TypographySize.MEDIUM}>
                {label}
            </StyledText>
        </StyledWrapper>
    );
};

export default memo(Checkbox);
