import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useToggle } from '@uidotdev/usehooks';

import { ROUTES } from '@/routes';
import { Icon } from '@ui/index';
import { UserDropdown } from '@modules/index';

const Nav = () => {
    const [ isOpenSidebar, onToggleSidebar ] = useToggle(false);

    useEffect(
        () => {
            if (isOpenSidebar) {
                document.body.classList.remove('sidenav-toggled');
            } else {
                document.body.classList.add('sidenav-toggled');
            }
        },
        [ isOpenSidebar ]
    );

    return (
        <nav
            className='topnav navbar navbar-expand shadow justify-content-between justify-content-sm-start navbar-light bg-white'
            id='sidenavAccordion'
        >
            {/* <!-- Sidenav Toggle Button--> */}
            <button
                className='btn btn-icon btn-transparent-dark order-1 order-lg-0 me-2 ms-lg-2 me-lg-0'
                id='sidebarToggle'
                onClick={() => onToggleSidebar()}
            >
                <Icon icon={isOpenSidebar ? 'x' : 'menu'} />
            </button>
            <NavLink className='navbar-brand pe-3 ps-4 ps-lg-2' to={ROUTES.VOYAGES_LIST}>
                <img src='/logo.svg' alt='Planemo' />
            </NavLink>
            <div className='navbar-nav align-items-center ms-auto me-4'>
                <UserDropdown />
            </div>
        </nav>
    );
};

export default Nav;
