import React, { forwardRef } from 'react';

import StyledInput from './styled/Input';

const Input = forwardRef((props, ref) => {
    const { type = 'text', placeholder = '', error, ...options } = props;

    return (
        <StyledInput
            type={type}
            className='form-control'
            placeholder={placeholder}
            ref={ref}
            error={error}
            {...options}
        />
    );
});

export default Input;
