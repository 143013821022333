import React, { memo } from 'react';

import { TypographyTag, TypographyColor, TypographySize, TypographyTransform } from './constants';

import StyledParagraph from './styled/Paragraph';

const Paragraph = ({
    tag = TypographyTag.P,
    className,
    children,
    color = TypographyColor.Main,
    variant = TypographySize.NORMAL,
    transform = TypographyTransform.NONE,
}) => (
    <StyledParagraph className={className} color={color} as={tag} variant={variant} transform={transform}>
        {children}
    </StyledParagraph>
);

export default memo(Paragraph);
