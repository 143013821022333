import request from '@api';

export function createVoyage(fileBinaryString) {
    return request({
        method: 'post',
        url: '/voyages',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        data: {
            file: fileBinaryString,
        },
    });
}
