import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { Checkbox } from '@ui/index';

import dt from '@constants/designTokens';

const getCheckboxStyles = ({ routeType }) => css`
    & > span {
        font-weight: bold;
        color: ${dt.Colors.Map.lines[routeType]};
    }
`;

export default styled(Checkbox)([getCheckboxStyles]);
