import { css } from '@emotion/react';
import styled from '@emotion/styled';

const bodyStyles = css`
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
    padding-top: 0;
`;

export default styled('div')(bodyStyles);
