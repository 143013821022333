import React from 'react';

import { Spinner } from '@ui/index';

import StyledWrapper from './styled/StyledWrapper';

const CenteredSpinner = (props) => {
    return (
        <StyledWrapper>
            <Spinner {...props} />
        </StyledWrapper>
    );
};

export default CenteredSpinner;
