import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

const actionStyles = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    display: none;
`;

export default styled('div')(actionStyles);
