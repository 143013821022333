import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Paragraph } from '@ui/index';

const paragraphStyles = css`
    margin: auto;
    text-align: center;
`;

export default styled(Paragraph)(paragraphStyles);
