import React, { useCallback } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { useAuth } from '@hooks/index';

import { Pages, ROUTES } from './routes';

const Authenticate = ({ children }) => {
    const { roles = [] } = useAuth();
    const onActionByQueryError = useCallback(({ code }) => {
        if (code === 401) {
            window.location = ROUTES.AUTH;
            return;
        }
    }, []);

    if (roles.length === 0) {
        window.location = ROUTES.AUTH;
        return;
    }

    return React.cloneElement(children, { onActionByQuery401Status: onActionByQueryError });
};

const router = createBrowserRouter([
    {
        path: ROUTES.UI,
        element: <Pages.Components />,
    },
    {
        path: ROUTES.AUTH,
        element: <Pages.Auth />,
    },
    {
        path: ROUTES.VOYAGES_LIST,
        element: (
            <Authenticate>
                <Pages.Voyages />
            </Authenticate>
        ),
    },
    {
        path: ROUTES.VOYAGES_EDIT,
        element: (
            <Authenticate>
                <Pages.VoyageEdit />
            </Authenticate>
        ),
    },
    {
        path: ROUTES.CAPTAIN_WORKSPACE,
        element: (
            <Authenticate>
                <Pages.CaptainWorkspace />
            </Authenticate>
        ),
    },
]);

const App = () => <RouterProvider router={router} />;

export default App;
