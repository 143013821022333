import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { rgba } from 'polished';

import dt from '@constants/designTokens';

const linkWrapperStyles = css`
    display: flex;
    align-items: center;
    line-height: normal;
    margin: 0.5rem 0;

    color: ${dt.Colors.Typography.Main};

    border-bottom: none;

    & > a {
        display: flex;
        align-items: center;
        line-height: normal;
        width: 100%;
        padding: 1rem;
        border-right: 0.125rem solid transparent;
        text-decoration: none;
        color: inherit;
    }

    & > .active {
        color: ${dt.Colors.Typography.Primary};
        border-color: ${dt.Colors.Border.Primary};
    }

    & > .active > span {
        color: ${dt.Colors.Typography.Primary};
    }

    &:hover {
        background: ${rgba(dt.Colors.Background.Primary, 0.12)};
    }
`;

export default styled('div')(linkWrapperStyles);
