import { css } from '@emotion/react';
import styled from '@emotion/styled';

const wrapperStyles = css`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 64px * 2);
    justify-content: center;
    align-items: center;
`;

export default styled('div')(wrapperStyles);
