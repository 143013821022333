import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { NavLink } from 'react-router-dom';

import { ROUTES, ROUTES_DYNAMIC } from '@/routes';
import { Icon, IconSize, TypographyTag, TypographySize } from '@ui/index';

import { useAuth } from '@hooks/index';

import StyledWrapper from './styled/Wrapper';

import StyledNav from './styled/Nav';
import StyledMenu from './styled/Menu';
import StyledLinkWrapper from './styled/LinkWrapper';
import StyledText from './styled/Text';

import { getActiveVoyages } from './api';

const Sidebar = () => {
    const { userID } = useAuth();
    const { data: activeVoyagesData = { data: [] } } = useQuery({
        queryFn: () => getActiveVoyages(),
        queryKey: `active-voyage-user-${userID}`,
        refetchOnWindowFocus: false,
    });

    const { data: activeVoyages } = activeVoyagesData;

    const activeVoyageID = useMemo(() => {
        if (activeVoyages.length) {
            const [voyage] = activeVoyages;
            return voyage.voyage_id;
        }

        return null;
    }, [activeVoyages]);

    return (
        <StyledWrapper>
            <StyledNav className='shadow-right sidenav-light'>
                <StyledMenu>
                    <div className='nav nav-borders flex-column'>
                        <StyledLinkWrapper>
                            <NavLink
                                to={ROUTES.VOYAGES_LIST}
                                className={({ isActive }) => (isActive ? 'active' : '')}
                            >
                                <Icon size={IconSize.MEDIUM} icon='list' />
                                <StyledText tag={TypographyTag.SPAN} variant={TypographySize.MEDIUM}>
                                    Voyages schedule
                                </StyledText>
                            </NavLink>
                        </StyledLinkWrapper>

                        {activeVoyageID && (
                            <StyledLinkWrapper>
                                <NavLink
                                    to={ROUTES_DYNAMIC.CAPTAIN_WORKSPACE(activeVoyageID)}
                                    className={({ isActive }) => (isActive ? 'active' : '')}
                                >
                                    <Icon size={IconSize.MEDIUM} icon='monitor' />
                                    <StyledText tag={TypographyTag.SPAN} variant={TypographySize.MEDIUM}>
                                        Captain workspace
                                    </StyledText>
                                </NavLink>
                            </StyledLinkWrapper>
                        )}
                    </div>
                </StyledMenu>
            </StyledNav>
        </StyledWrapper>
    );
};

export default Sidebar;
