import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useMutation } from 'react-query';
import Cookie from 'js-cookie';
import * as Yup from 'yup';

import CONFIG from '@config';
import { InputField } from '@components/index';
import { Button, Paragraph, TypographyColor } from '@ui/index';
import request from '@api';

const SignupSchema = Yup.object().shape({
    email: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
});

const AuthForm = ({ onSuccess = void 0 }) => {
    const [apiErrorMessage, setAPIErrorMessage] = useState('');
    const authMutation = useMutation({
        mutationFn: ({ email, password }) => {
            setAPIErrorMessage('');

            return request({
                method: 'post',
                url: '/auth/sign-in',
                data: { email: email.trim(), password: password.trim() },
            });
        },
        onSuccess: ({ data: { access_token: accessToken } }) => {
            Cookie.set(CONFIG.TOKEN_NAME, accessToken);
            onSuccess();
        },
        onError: ({ message }) => {
            setAPIErrorMessage(message);
        },
    });

    return (
        <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={SignupSchema}
            onSubmit={(values) => {
                authMutation.mutate(values);
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (
                <Form className='d-flex w-100 flex-column' onSubmit={handleSubmit}>
                    <div className='w-100 flex-fill mb-4'>
                        <Field name='email'>
                            {({ field }) => (
                                <InputField
                                    {...field}
                                    label='Email'
                                    placeholder='email@planemo.com'
                                    onChange={handleChange}
                                    value={values.email}
                                    error={errors.email}
                                />
                            )}
                        </Field>
                    </div>
                    <div className='w-100 flex-fill'>
                        <Field name='password'>
                            {({ field }) => (
                                <InputField
                                    {...field}
                                    label='Password'
                                    type='password'
                                    placeholder='password'
                                    onChange={handleChange}
                                    value={values.password}
                                    error={errors.password}
                                />
                            )}
                        </Field>
                    </div>
                    <div className='d-flex justify-content-center mt-4 mb-2'>
                        <Button type='submit' className='w-75' disabled={authMutation.isLoading}>
                            Login
                        </Button>
                    </div>
                    {apiErrorMessage && (
                        <Paragraph className='text-center' color={TypographyColor.Danger}>
                            {apiErrorMessage}
                        </Paragraph>
                    )}
                </Form>
            )}
        </Formik>
    );
};

export default AuthForm;
