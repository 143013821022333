import Components from '@pages/ui/index';
import Auth from '@pages/auth/Auth';
import Voyages from '@pages/voyages';
import VoyageEdit from '@pages/voyageEdit';
import CaptainWorkspace from '@pages/captainWorkspace';

export const Pages = {
    Components,
    Auth,
    Voyages,
    VoyageEdit,
    CaptainWorkspace,
};

export const ROUTES = {
    VOYAGES_LIST: '/',
    AUTH: '/auth',
    VOYAGES_EDIT: '/voyages/:voyageID',
    CAPTAIN_WORKSPACE: '/captain-workspace/:voyageID',
    UI: '/ui',
};

export const ROUTES_DYNAMIC = {
    VOYAGES_LIST: () => ROUTES.VOYAGES_LIST,
    AUTH: () => ROUTES.AUTH,
    VOYAGES_EDIT: (voyageID) => ROUTES.VOYAGES_EDIT.replace(':voyageID', voyageID),
    CAPTAIN_WORKSPACE: (voyageID) => ROUTES.CAPTAIN_WORKSPACE.replace(':voyageID', voyageID),
    UI: () => ROUTES.UI,
};
