import React, { memo, useRef, useLayoutEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5radar from '@amcharts/amcharts5/radar';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

const WithGradient = (props) => {
    const { id, min, max, value } = props;
    const rootRef = useRef(null);

    useLayoutEffect(
        () => {
            const root = am5.Root.new(id);

            root.setThemes([ am5themes_Animated.new(root) ]);

            // Create chart
            // https://www.amcharts.com/docs/v5/charts/radar-chart/
            const chart = root.container.children.push(
                am5radar.RadarChart.new(root, {
                    panX: false,
                    panY: false,
                    startAngle: 180,
                    endAngle: 360,
                })
            );

            // Create axis and its renderer
            // https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Axes
            const axisRenderer = am5radar.AxisRendererCircular.new(root, {
                innerRadius: -1,
                strokeOpacity: 1,
                strokeWidth: 10,
                strokeGradient: am5.LinearGradient.new(root, {
                    rotation: 0,
                    stops: [
                        { color: am5.color(0x19d228) },
                        { color: am5.color(0xf4fb16) },
                        { color: am5.color(0xf6d32b) },
                        { color: am5.color(0xfb7116) },
                    ],
                }),
            });

            const xAxis = chart.xAxes.push(
                am5xy.ValueAxis.new(root, {
                    maxDeviation: 0,
                    min,
                    max,
                    strictMinMax: true,
                    renderer: axisRenderer,
                })
            );

            // Add clock hand
            // https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Clock_hands
            const axisDataItem = xAxis.makeDataItem({
                value,
            });

            axisDataItem.set(
                'bullet',
                am5xy.AxisBullet.new(root, {
                    sprite: am5radar.ClockHand.new(root, {
                        radius: am5.percent(102),
                        innerRadius: am5.percent(85),
                        layer: 1,
                        pinRadius: 0,
                    }),
                })
            );

            xAxis.createAxisRange(axisDataItem);

            // Make stuff animate on load
            chart.appear(1000, 100);

            rootRef.current = root;

            return () => {
                root.dispose();
                rootRef.current.dispose();
            };
        },
        [ id, rootRef, min, max, value ]
    );

    return <div style={{ width: '100%', height: '200px' }} id={id} />;
};

export default memo(WithGradient);
