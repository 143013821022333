import React from 'react';
import classcat from 'classcat';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Button } from '@ui/index';

const toggleStyles = css`
    border: none;
`;

const Toggle = ({ children, className, onClick }) => {
    const classes = classcat(['dropdown-toggle', className]);

    return (
        <Button className={classes} onClick={onClick}>
            {children}
        </Button>
    );
};

export default styled(Toggle)(toggleStyles);
