import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Icon } from '@ui/index';

import dt from '@constants/designTokens';

const styledEndIcon = css`
    color: ${dt.Colors.Background.Success};
`;

export default styled(Icon)(styledEndIcon);
