import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { Layout, WorkspaceTools, VesselGauges, VesselOptimizedRoute } from '@modules/index';
import { WithErrorMutationHandler, CenteredSpinner, Error } from '@components/index';
import { SpinnerSize } from '@ui/index';

import StyledGaugesWrapper from './styled/GaugesWrapper';
import StyledOptimizedRouteWrapper from './styled/OptimizedRouteWrapper';

import { getVoyage } from './api';

const CaptainWorkspace = ({ onHandleAPIError, onActionByQuery401Status }) => {
    const { voyageID } = useParams();
    const { data: voyageData, isLoading } = useQuery({
        queryKey: 'get-voyage',
        queryFn: () => getVoyage(voyageID),
        refetchInterval: false,
        refetchOnWindowFocus: false,
        onError: onActionByQuery401Status,
    });

    if (isLoading) {
        return <CenteredSpinner message='Loading...' size={SpinnerSize.MAX} />;
    }

    if (!voyageData) {
        return <Error code='404' message='Captain workspace is not found' />;
    }

    const { data: voyage } = voyageData;

    return (
        <Layout>
            <div className='container-fluid'>
                <div className='mt-4 mb-4'>
                    <WorkspaceTools voyage={voyage} onSetApiError={onHandleAPIError} />
                </div>
                <div className='d-flex'>
                    <StyledGaugesWrapper>
                        <VesselGauges voyageID={voyageID} vessel={voyage.vessel} />
                    </StyledGaugesWrapper>
                    <StyledOptimizedRouteWrapper>
                        <VesselOptimizedRoute vesselID={voyage.vessel.vessel_id} voyageID={voyageID} />
                    </StyledOptimizedRouteWrapper>
                </div>
            </div>
        </Layout>
    );
};

export default memo(WithErrorMutationHandler(CaptainWorkspace));
