import { css } from '@emotion/react';

import dt from '@constants/designTokens';

export const getDangerStyles = () => css`
    background: ${dt.Colors.Background.Danger};
    color: ${dt.Colors.Typography.White};
    border-color: ${dt.Colors.Border.Danger};

    &:hover {
        background: ${dt.Colors.Background.Danger600};
        color: ${dt.Colors.Typography.White};
    }

    &:active {
        background: ${dt.Colors.Background.Danger800} !important;
        color: ${dt.Colors.Typography.White} !important;
    }
`;
