import { css } from '@emotion/react';
import styled from '@emotion/styled';

const wrapperStyles = css`
    display: inline-flex;
    align-items: center;
    gap: 8px;
`;

export default styled('div')(wrapperStyles);
