import axios from 'axios';
import Cookie from 'js-cookie';

import CONFIG from '@config';

const instance = axios.create({
    baseURL: 'https://dev.planemo.ru/v1',
    headers: {
        Accept: 'application/json',
    },
});

instance.interceptors.request.use((config) => {
    const headers = {
        ...config.headers,
        Authorization: `Bearer ${Cookie.get(CONFIG.TOKEN_NAME) || ''}`,
    };

    return {
        ...config,
        headers,
    };
});

const request = (config = {}) => {
    return instance.request(config).catch(({ response }) => {
        const {
            status,
            data: { error },
        } = response;

        if (status === 401) {
            Cookie.remove(CONFIG.TOKEN_NAME, { path: '/' });
            return Promise.reject({ code: 401, message: error });
        }

        return Promise.reject({ code: status, message: error });
    });
};

export default request;
