import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Header } from '@ui/index';

const headerStyles = css`
    font-weight: 700;
    padding: 0.3rem 1rem;
    font-weight: 400;
`;

export default styled(Header)(headerStyles);
