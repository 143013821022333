import base from './base';

import { TYPE as ROUTE_TYPE } from '@constants/route';

const lines = {
    [ROUTE_TYPE.PLANNED]: base.Blue,
    [ROUTE_TYPE.ORIGINAL]: base.Gray800,
    [ROUTE_TYPE.OPTIMIZED]: base.Green,
};

const points = {
    [ROUTE_TYPE.PLANNED]: base.Blue,
    [ROUTE_TYPE.ORIGINAL]: base.Gray800,
    [ROUTE_TYPE.OPTIMIZED]: base.Green,
};

const landscape = {
    water: base.Blue100,
    land: base.WhiteDark,
};

export default {
    landscape,
    lines,
    points,
    currentShip: '#EC9514',
    borders: base.Gray800,
};
