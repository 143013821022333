import React, { useMemo } from 'react';
import { useMutation } from 'react-query';

import { Button, Paragraph, BlockTag, TypographySize } from '@ui/index';
import { DragNDrop, Spinner, ButtonVariant } from '@ui/index';
import { WithErrorMutationHandler } from '@components/index';

import StyledWrapper from './styled/Wrapper';
import StyledFinal from './styled/Final';

const VoyagerImportFile = ({ onClose, actionByImport, onSuccess = () => {}, onHandleAPIError }) => {
    const { mutate, isLoading, isError, isSuccess, reset } = useMutation({
        mutationFn: (file) => {
            return new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.readAsArrayBuffer(file); // TODO: Сейчас файл всегда проваливается сюда

                reader.onload = function () {
                    resolve(reader.result);
                };

                reader.onerror = function () {
                    reject();
                };
            }).then((binaryString) => {
                return actionByImport(binaryString);
            });
        },
        onSuccess,
        onError: onHandleAPIError,
    });

    const wrapperNode = useMemo(() => {
        if (isLoading) {
            return <Spinner message='Loading file...' />;
        }

        if (isError) {
            return (
                <StyledFinal>
                    <Paragraph tag={BlockTag.P} variant={TypographySize.MEDIUM}>
                        There was a file download error.
                    </Paragraph>

                    <br />

                    <Button onClick={reset}>Repeat</Button>
                </StyledFinal>
            );
        }

        if (isSuccess) {
            return (
                <StyledFinal>
                    <Paragraph tag={BlockTag.P} variant={TypographySize.MEDIUM}>
                        File uploaded successfully
                    </Paragraph>

                    <br />

                    <Button onClick={onClose} variant={ButtonVariant.SUCCESS}>
                        OK
                    </Button>
                </StyledFinal>
            );
        }

        return (
            <DragNDrop
                accept={{
                    'application/octet-stream': ['.rtz'],
                }}
                multiple={false}
                onSelect={(files, error) => {
                    mutate(files[0]);
                }}
                placeholder='Drag and Drop file or'
                buttonText='Choise file to the area'
            />
        );
    }, [isLoading, isError, isSuccess, mutate, reset, onClose]);

    return <StyledWrapper>{wrapperNode}</StyledWrapper>;
};

export default WithErrorMutationHandler(VoyagerImportFile);
