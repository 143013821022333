import { css } from '@emotion/react';
import styled from '@emotion/styled';

import dt from '@constants/designTokens';

const errorStyles = css`
    color: ${dt.Colors.Typography.Danger};
    font-size: 12px;
`;

export default styled('span')(errorStyles);
