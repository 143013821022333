import { css } from '@emotion/react';
import styled from '@emotion/styled';

const navStyles = css`
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-wrap: nowrap;
`;

export default styled('nav')(navStyles);
