import React, { memo } from 'react';
import classcat from 'classcat';

import { VARIANT as ButtonVarian } from './constants';

import StyledButton from './styled/Button';

const Button = (props) => {
    const { type = 'button', variant = ButtonVarian.PRIMARY, className, children, ...restProps } = props;

    const mainClasses = classcat(['btn', className]);

    return (
        <StyledButton type={type} variant={variant} className={mainClasses} {...restProps}>
            {children}
        </StyledButton>
    );
};

export default memo(Button);
