import React, { memo } from 'react';

import * as am5map from '@amcharts/amcharts5/map';
import am5geodata_worldHigh from '@amcharts/amcharts5-geodata/worldHigh';

import { RoutesMap } from '@components/index';

import StyledWrapper from './styled/Wrapper';

const settings = {
    mapChart: {
        panX: 'none',
        panY: 'none',
        wheelY: 'none',
        projection: am5map.geoOrthographic(),
        maxPanout: 0,
    },
    mapPolygonSeries: {
        geoJSON: am5geodata_worldHigh,
        exclude: [ 'AQ' ],
    },
};

const PreviewRoutesMap = ({ routes, vesselPoint, vesselCourse, width, height }) => {
    return (
        <StyledWrapper width={width} height={height}>
            <RoutesMap
                routes={routes}
                vesselPoint={vesselPoint}
                vesselCourse={vesselCourse}
                settings={settings}
                id='preview-routes-map'
            />
        </StyledWrapper>
    );
};

export default memo(PreviewRoutesMap);
