import React, { memo } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { HeadingTag, TypographyTransform, TextSize } from './constants';

const getHeaderStyles = ({ transform = TypographyTransform.NONE, variant = TextSize.MEDIUM }) => css`
    text-transform: ${transform};

    ${variant === TextSize.EXTRA_LARGE &&
    css`
        font-size: 6rem;
    `} ${variant === TextSize.LARGE &&
    css`
        font-size: 3rem;
    `};
`;

const Header = ({ tag: Tag = HeadingTag.H1, children, className }) => {
    return <Tag className={className}>{children}</Tag>;
};

export default memo(styled(Header)([getHeaderStyles]));
