import React from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

const wrapperStyles = css`
    display: flex;
    position: relative;
`;

const Wrapper = ({ children, className }, ref) => {
    return (
        <div className={className} ref={ref}>
            {children}
        </div>
    );
};

export default styled(React.forwardRef(Wrapper))(wrapperStyles);
