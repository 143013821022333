import { css } from '@emotion/react';
import styled from '@emotion/styled';

import dt from '@constants/designTokens';

const getBackdropStyles = ({ isOpen }) => css`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    opacity: ${isOpen ? 0.5 : 0};
    background: ${dt.Colors.Background.Black};
    transition: opacity 0.15s linear;
`;

export default styled('div')([getBackdropStyles]);
