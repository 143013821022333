import React from 'react';
import { useDropzone } from 'react-dropzone';

import { Button, TypographySize } from '@ui/index';

import StyledWrapper from './styled/DragNDropWrapper';
import StyledParagraph from './styled/DragNDropParagraph';

function DragNDrop(props) {
    const { placeholder = '', onSelect, buttonText = '', ...dragProps } = props;
    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        maxSize: 3e6, // 3Mb
        onDrop: onSelect,
        ...dragProps,
    });

    return (
        <StyledWrapper
            {...getRootProps()}
            isFocused={isFocused}
            isDragAccept={isDragAccept}
            isDragReject={isDragReject}
        >
            <input {...getInputProps()} />
            <StyledParagraph variant={TypographySize.MEDIUM}>
                {placeholder}
                <br />
                {buttonText && <Button className='mt-2'>{buttonText}</Button>}
            </StyledParagraph>
        </StyledWrapper>
    );
}

export default DragNDrop;
