import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Button } from '@ui/index';

const closeStyles = css`
    width: 32px;
    height: 32px;
    margin-left: auto;
`;

export default styled(Button)(closeStyles);
