import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { Layout, VoyagesList, VoyagesTools } from '@modules/index';
import { CenteredSpinner } from '@components/index';
import { SpinnerSize } from '@ui/index';

import { getVoyages } from './api';

const Voyages = ({ onActionByQuery401Status }) => {
    const [filterRoute, onChangeFilterRoute] = useState('');
    const {
        data: { data } = {},
        isLoading,
        refetch,
    } = useQuery({
        queryKey: 'voyages',
        queryFn: getVoyages,
        onError: onActionByQuery401Status,
    });

    if (isLoading) {
        return <CenteredSpinner message='Loading...' size={SpinnerSize.MAX} />;
    }

    return (
        <Layout>
            <div className='container-fluid'>
                <div className='mt-4 mb-4'>
                    <VoyagesTools onRefetchVoyages={refetch} onFilterByRoute={onChangeFilterRoute} />
                </div>
                <div className='mb-4'>
                    <VoyagesList
                        voyages={data.voyages.filter(({ route_name }) =>
                            route_name.toLowerCase().includes(filterRoute.trim())
                        )}
                    />
                </div>
            </div>
        </Layout>
    );
};

export default (props) => <Voyages {...props} />;
