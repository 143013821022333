import { css } from '@emotion/react';
import styled from '@emotion/styled';

const inputStyles = css`
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
    z-index: -1;

    &:checked + *:after {
        display: block;
    }
`;

export default styled('input')(inputStyles);
