import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Icon } from '@ui/index';

import dt from '@constants/designTokens';

const pointIconStyles = css`
    position: absolute;
    top: calc(-100% - 7px);
    left: calc(50% - 5px);
    color: ${dt.Colors.Background.Success};
`;

export default styled(Icon)(pointIconStyles);
