import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { rgba } from 'polished';

import dt from '@constants/designTokens';

import { SIZE } from '../constants';

const getSpinnerStyles = ({ size = SIZE.STANDART }) => css`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid ${rgba(dt.Colors.Border.White, 0.5)};
    border-top-color: ${dt.Colors.Border.Primary};

    animation: spin 1s ease-in-out infinite;

    ${size === SIZE.MIN &&
    css`
        width: 24px;
        height: 24px;
        border-width: 2px;
    `} ${size === SIZE.STANDART &&
    css`
        width: 48px;
        height: 48px;
    `} ${size === SIZE.MAX &&
    css`
        width: 72px;
        height: 72px;
    `} @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
`;

export default styled('div')([getSpinnerStyles]);
