import request from '@api';

export function getEnginesRPM(vesselID) {
    return request({
        method: 'get',
        url: `/vessels/${vesselID}/engines/rpm`,
    });
}

export function getAzipodEnginesAngles(vesselID) {
    return request({
        method: 'get',
        url: `/vessels/${vesselID}/engines/angle`,
    });
}

export function getEnginesPower(vesselID) {
    return request({
        method: 'get',
        url: `/vessels/${vesselID}/engines/power`,
    });
}

export function getEnginesSuggestedPower(voyageID) {
    return request({
        method: 'get',
        url: `/voyages/${voyageID}/suggested-power`,
    });
}
