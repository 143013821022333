import React, { useState, createContext } from 'react';

import UserError from './4xx';
import ServerError from './5xx';

const Error = (props) => {
    const { code } = props;

    if (+code >= 400 && +code < 500) {
        return <UserError {...props} />;
    }

    if (+code >= 500 && +code <= 599) {
        return <ServerError {...props} />;
    }

    return null;
};

const ErrorCtx = createContext({});

const WithErrorMutationHandler = (Component) => {
    const ErrorProvider = (props) => {
        const [ error, setError ] = useState(null);
        const onResetError = () => setError(null);

        const errorContext = { error, setError };

        if (error && error.code >= 500) {
            return (
                <ErrorCtx.Provider value={errorContext}>
                    <Error code={error.code} message={error.message} onCloseError={onResetError} />
                </ErrorCtx.Provider>
            );
        }

        return (
            <ErrorCtx.Provider value={errorContext}>
                <Component {...props} onHandleAPIError={setError} />

                {error && <Error code={error.code} message={error.message} onCloseError={onResetError} />}
            </ErrorCtx.Provider>
        );
    };

    return ErrorProvider;
};

export { WithErrorMutationHandler, Error };
