import React, { memo, useRef, useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5map from '@amcharts/amcharts5/map';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

import dt from '@constants/designTokens';
import { TYPE as ROUTE_TYPE } from '@constants/route';

import StyledWrapper from './styled/Wrapper';

import { defaultSettings } from './settings';
import { GEOMETRY_TYPE } from './constants';

const tooltipHTMLByRouteType = {
    [ROUTE_TYPE.PLANNED]: () => `
        <div style="color: #fff">NAME: {properties.name}</div>
        <div style="color: #fff">ETA: {properties.eta || '-'}</div>
        <div style="color: #fff">Speed: {properties.speed}</div>
    `,
    [ROUTE_TYPE.OPTIMIZED]: () => `
        <div style="color: #fff">POWER: {properties.power}</div>
    `,
};

const INITIAL_ZOOM_SETTINGS = {
    zoom: 1,
    duration: 1000,
    center: true,
    use3D: true,
};

const RoutesMap = ({
    routes = [],
    settings = {},
    id = '',
    vesselPoint = {},
    vesselCourse = {},
    initialZoomSettings = INITIAL_ZOOM_SETTINGS,
}) => {
    const mapRef = useRef(null);
    let rootRef = useRef(null);
    let lineSeriesArr = useRef([]);
    let pointSeriesArr = useRef([]);

    useEffect(
        () => {
            rootRef.current = am5.Root.new(id);
            rootRef.current.setThemes([ am5themes_Animated.new(rootRef.current) ]);

            // Create map
            const map = rootRef.current.container.children.push(
                am5map.MapChart.new(rootRef.current, {
                    ...defaultSettings.mapChart,
                    ...settings.mapChart,
                })
            );

            // Create polygon series
            map.series.push(
                am5map.MapPolygonSeries.new(rootRef.current, {
                    ...defaultSettings.mapPolygonSeries,
                    ...settings.mapPolygonSeries,
                })
            );

            const backgroundSeries = map.series.unshift(am5map.MapPolygonSeries.new(rootRef.current, {}));

            backgroundSeries.mapPolygons.template.setAll({
                fill: dt.Colors.Map.landscape.water,
                stroke: dt.Colors.Map.landscape.water,
            });

            backgroundSeries.data.push({
                geometry: am5map.getGeoRectangle(90, 180, -90, -180),
            });

            mapRef.current = map;

            rootRef.current.events.once('frameended', () => {
                if (!vesselPoint.geometry) {
                    return;
                }

                const { coordinates: initialCoordinates = [] } = vesselPoint.geometry;
                const [ longitude, latitude ] = initialCoordinates;

                mapRef.current.zoomToGeoPoint(
                    { longitude, latitude },
                    initialZoomSettings.zoom,
                    initialZoomSettings.center,
                    initialZoomSettings.duration,
                    initialZoomSettings.use3D ? -longitude : initialZoomSettings.rotationX,
                    initialZoomSettings.use3D ? -latitude : initialZoomSettings.rotationY
                );
            });

            return () => {
                rootRef.current.dispose();
                mapRef.current.dispose();

                rootRef.current = null;
                mapRef.current = null;
            };
        },
        [ id, vesselPoint, settings.mapPolygonSeries, settings.mapChart, initialZoomSettings ]
    );

    useEffect(
        () => {
            if (vesselPoint.geometry) {
                let pointImageSeries = mapRef.current.series.push(
                    am5map.MapPointSeries.new(rootRef.current, {})
                );
                let vessel = am5.Picture.new(rootRef.current, {
                    src: '/ship-dir.svg',
                    scale: 1,
                    centerY: am5.p50,
                    centerX: am5.p50,
                    fill: dt.Colors.Map.currentShip,
                    width: 38,
                    height: 38,
                    rotation: vesselCourse.course_degrees,
                    layer: 1,
                });

                pointImageSeries.bullets.push(function () {
                    const container = am5.Container.new(rootRef.current, {
                        fill: dt.Colors.Map.currentShip,
                        tooltipHTML: `<div style="color: #fff">NAME: {properties.name}</div>`,
                    });
                    container.children.push(vessel);

                    return am5.Bullet.new(rootRef.current, {
                        sprite: container,
                    });
                });

                pointImageSeries.pushDataItem({
                    geometry: vesselPoint.geometry,
                    properties: vesselPoint.properties,
                    positionOnLine: 0,
                    autoRotate: true,
                });
            }

            routes
                .filter(({ type }) => type !== ROUTE_TYPE.ORIGINAL)
                .forEach(({ route, type: globalType }) => {
                    let pointSeries = mapRef.current.series.push(
                        am5map.MapPointSeries.new(rootRef.current, {})
                    );
                    let points = [];

                    if (route.features.length) {
                        points = route.features.filter(
                            ({ geometry: { type } }) => type === GEOMETRY_TYPE.MULTI_POINT
                        );
                    }

                    points.forEach(({ geometry: { coordinates }, properties }) => {
                        pointSeries.pushDataItem({
                            longitude: coordinates[0][0],
                            latitude: coordinates[0][1],
                            properties,
                        });
                    });

                    // Point painting
                    pointSeries.bullets.push(function () {
                        const circle = am5.Circle.new(rootRef.current, {
                            radius: 3,
                            fillOpacity: 1,
                            fill: dt.Colors.Map.points[globalType],
                            tooltipHTML: tooltipHTMLByRouteType[globalType](),
                        });

                        return am5.Bullet.new(rootRef.current, {
                            sprite: circle,
                        });
                    });

                    pointSeriesArr.current.push(pointSeries);
                });

            routes.forEach(({ route, type: globalType }) => {
                let lineSeries = mapRef.current.series.push(am5map.MapLineSeries.new(rootRef.current, {}));

                route.features
                    .filter(({ geometry: { type } }) => type === GEOMETRY_TYPE.LINE_STRING)
                    .forEach((routeItem) => {
                        lineSeries.pushDataItem({
                            geometry: {
                                type: GEOMETRY_TYPE.LINE_STRING,
                                coordinates: routeItem.geometry.coordinates,
                            },
                            properties: routeItem.properties,
                        });

                        lineSeries.mapLines.template.setAll({
                            stroke: dt.Colors.Map.lines[globalType],
                            strokeOpacity: 1,
                            strokeWidth: 2,
                        });

                        if (globalType === ROUTE_TYPE.ORIGINAL) {
                            lineSeries.mapLines.template.setAll({
                                strokeDasharray: [ 5, 5 ],
                            });
                        }

                        lineSeriesArr.current.push(lineSeries);
                    });
            });

            return () => {
                lineSeriesArr.current.forEach((lineSeries) => {
                    lineSeries.dispose();
                });
                pointSeriesArr.current.forEach((pointSeries) => {
                    pointSeries.dispose();
                });

                lineSeriesArr.current = [];
                pointSeriesArr.current = [];
            };
        },
        [ vesselPoint, vesselCourse, routes, lineSeriesArr, pointSeriesArr ]
    );

    return <StyledWrapper id={id} />;
};

export default memo(RoutesMap);
