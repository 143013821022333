import React, { memo } from 'react';

import { Icon, Paragraph } from '@ui/index';
import { ButtonVariant, TypographyTag } from '@ui/index';

import StyledItem from './styled/Item';

const Item = ({ icon, title, onClick, ...restProps }) => {
    return (
        <StyledItem variant={ButtonVariant.LINK} onClick={onClick} {...restProps}>
            <Paragraph tag={TypographyTag.SPAN} className='dropdown-item-icon me-2'>
                <Icon icon={icon} />
            </Paragraph>
            <Paragraph tag={TypographyTag.SPAN}>{title}</Paragraph>
        </StyledItem>
    );
};

export default memo(Item);
