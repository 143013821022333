import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

import { GraphElements2D, CenteredSpinner } from '@components/index';
import { IconSize, Paragraph, TextSize } from '@ui/index';

import StyledEndIcon from './styled/EndIcon';
import StyledStartIcon from './styled/StartIcon';

import { getSuggestedActions } from './api';

const VesselCheckpointsPath = ({ voyageID }) => {
    const { data: suggestedActions = {}, isLoading } = useQuery({
        queryKey: `suggested-actions/${voyageID}`,
        queryFn: () => getSuggestedActions(voyageID),
        retry: 1,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });

    const endNode = useMemo(() => <StyledEndIcon icon='flag' size={IconSize.LARGE_M} />, []);

    if (isLoading) {
        return (
            <div className='d-flex mb-5'>
                <CenteredSpinner message='Loading power advices' />
            </div>
        );
    }

    const { data: { power_advices: powerAdvices = [] } = {} } = suggestedActions;

    if (powerAdvices.length === 0) {
        return (
            <div className='d-flex mx-auto mt-auto justify-content-center'>
                <Paragraph variant={TextSize.MEDIUM}>No data for power advices</Paragraph>
            </div>
        );
    }

    return (
        <GraphElements2D.Segment
            startNode={<StyledStartIcon src='/ship.png' />}
            endNode={endNode}
            points={powerAdvices}
        />
    );
};

export default VesselCheckpointsPath;
