import { css } from '@emotion/react';
import styled from '@emotion/styled';

const previewStyles = css`
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 100%;
`;

export default styled('img')(previewStyles);
