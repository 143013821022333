const MINUTES = {
    MM: [/[0-5]/, /[0-9]/],
};

const HOURS = {
    HH: [/[0-2]/, /[0-9]/],
};

const DAY = {
    DD: [/[0-3]/, /\d/],
};

const MONTH = {
    MM: [/[01]/, /\d/],
};

const YEAR = {
    YYYY: [/[12]/, /\d/, /\d/, /\d/],
};

export default {
    MINUTES,
    HOURS,

    TIME: {
        'HH:MM': [...HOURS['HH'], ':', ...MINUTES['MM']],
    },

    DAY,
    MONTH,
    YEAR,

    DATE: {
        'DD.MM.YYYY': [...DAY['DD'], '.', ...MONTH['MM'], '.', ...YEAR['YYYY']],
        'MM.YYYY': [...MONTH['MM'], '.', ...YEAR['YYYY']],
    },
};

export const match =
    (mask = [], strict = true) =>
    (string) => {
        if (!string) {
            string = '';
        }

        if (strict && mask.length !== string.length) {
            return false;
        }

        return mask.every((symbol, index) => {
            if (symbol instanceof RegExp) {
                return new RegExp(symbol).test(string[index]);
            }

            return Object.is(symbol, string[index]);
        });
    };
