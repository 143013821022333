import { css } from '@emotion/react';
import styled from '@emotion/styled';

import dt from '@constants/designTokens/index';

const dividerStyles = css`
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid ${dt.Colors.Border.Grey};
    opacity: 1;
`;

export default styled('div')(dividerStyles);
