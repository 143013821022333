import React from 'react';

import { Gauges } from '@components/index';
import { Header, HeadingTag, Paragraph } from '@ui/index';

import StyledWrapper from './styled/Wrapper';
import StyledGaugeWrapper from './styled/GaugeWrapper';
import StyledValue from './styled/Value';
import StyledCompareTag from './styled/CompareTag';

const AzipodGauge = ({ min, max, value, id, suffix, suggestedValue }) => {
    return (
        <StyledWrapper>
            <StyledGaugeWrapper>
                <Gauges.WithGradient id={id} min={min} max={max} value={value} />

                <StyledValue>
                    <Header>{value}</Header>
                    <Header tag={HeadingTag.H4}>{suffix}</Header>
                </StyledValue>
            </StyledGaugeWrapper>

            <div className='d-flex justify-content-center mt-4'>
                <div className='d-flex flex-column text-center'>
                    <StyledCompareTag>{suggestedValue.toFixed(2)}</StyledCompareTag>
                    <Paragraph>Task</Paragraph>
                </div>

                <div className='d-flex flex-column mx-2 text-center'>
                    <StyledCompareTag>{value.toFixed(2)}</StyledCompareTag>
                    <Paragraph>Current</Paragraph>
                </div>

                <div className='d-flex flex-column text-center'>
                    <StyledCompareTag>{(suggestedValue - value).toFixed(2)}</StyledCompareTag>
                    <Paragraph>Diff</Paragraph>
                </div>
                {/* <StyledCompare>
                    <StyledCompareTag>{value}</StyledCompareTag>
                    <Paragraph>Текущее</Paragraph>
                </StyledCompare>
                <StyledCompare>
                    <StyledCompareTag>{suggestedValue - value}</StyledCompareTag>
                    <Paragraph>Задание</Paragraph>
                </StyledCompare> */}
            </div>
        </StyledWrapper>
    );
};

export default AzipodGauge;
