import base from './base';

export default {
    Main: base.Black800,

    Primary: base.Blue,
    Success: base.Green,
    Danger: base.Red,

    Gray: base.Gray400,

    White: base.TrueWhite,
    Transparent: base.Transparent,
};
