import { css } from '@emotion/react';
import styled from '@emotion/styled';

const titleStyles = css`
    border-bottom: 2px solid black;
    display: flex;
    padding-bottom: 0.4rem;
    gap: 2rem;
`;

export default styled('div')(titleStyles);
