import { css } from '@emotion/react';
import styled from '@emotion/styled';

const vectorPointWrapperStyles = css`
    flex: 1;
    margin: 0 30px;
    position: relative;

    &:first-of-type {
        margin-left: 15px;
    }

    &:last-child {
        margin-right: 15px;
    }
`;

export default styled('div')(vectorPointWrapperStyles);
