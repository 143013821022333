import React, { useState } from 'react';
import { useClickAway } from '@uidotdev/usehooks';

import StyledWrapper from './styled/Wrapper';
import StyledToggle from './styled/Toggle';
import StyledContent from './styled/Content';

import { DropdownPosition } from './constants';

const Dropdown = ({
    wrapperClassName,
    toggleClassName,
    contentNode,
    toggleNode,
    position = DropdownPosition.LEFT,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const wrapperRef = useClickAway(() => {
        setIsOpen(false);
    });

    return (
        <StyledWrapper ref={wrapperRef} className={wrapperClassName}>
            <StyledToggle className={toggleClassName} onClick={() => setIsOpen(!isOpen)}>
                {toggleNode}
            </StyledToggle>
            {isOpen && (
                <StyledContent className='animated--fade-in-up' position={position}>
                    {contentNode}
                </StyledContent>
            )}
        </StyledWrapper>
    );
};

export default Dropdown;
