export const STATUS = {
    created: 'created',
    started: 'started',
    completed: 'completed',
};

export const STATUS_LABEL = {
    [STATUS.created]: 'Created',
    [STATUS.started]: 'Start',
    [STATUS.completed]: 'Completed',
};
