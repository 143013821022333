import React from 'react';

import { TypographySize, IconSize, Tooltip } from '@ui/index';

import Vector from './Vector';

import StyledVectorPointWrapper from './styled/segment/VectorPointWrapper';
import StyledWrapper from './styled/segment/Wrapper';
import StyledStart from './styled/segment/Start';
import StyledEnd from './styled/segment/End';
import StyledLine from './styled/segment/Line';
import StyledPointWrapper from './styled/vector/PointWrapper';
import StyledPoint from './styled/vector/Point';
import StyledPointIcon from './styled/segment/PointIcon';
import StyledPointTime from './styled/segment/PointTime';

const Segment = ({ startNode, endNode, points = [] }) => {
    return (
        <StyledWrapper>
            <StyledStart>{startNode}</StyledStart>
            <div className='d-flex w-100'>
                {points.map((point, index) => {
                    const { recommended_engines_power: advices } = point;

                    return (
                        <StyledVectorPointWrapper key={index}>
                            <Vector title={point.change_after} />

                            {points.length !== index + 1 && (
                                <StyledPointWrapper>
                                    <StyledPointIcon icon='flag' size={IconSize.LARGE_S} />
                                    <Tooltip
                                        anchor={<StyledPoint />}
                                        offset={20}
                                        style={{ transform: 'translateX(12%)' }}
                                    >
                                        Port: {advices.port}
                                        <br />
                                        Center: {advices.center}
                                        <br />
                                        Stbd: {advices.stbd}
                                    </Tooltip>
                                    <StyledPointTime variant={TypographySize.LARGE}>
                                        {point.arrival_date}
                                    </StyledPointTime>
                                </StyledPointWrapper>
                            )}
                        </StyledVectorPointWrapper>
                    );
                })}
            </div>
            <StyledEnd>{endNode}</StyledEnd>
            <StyledLine />
        </StyledWrapper>
    );
};

export default Segment;
