import request from '@api';

export function startVoyage(voyageID) {
    return request({
        method: 'post',
        url: `/voyages/${voyageID}/start`,
    });
}

export function deleteVoyage(voyageID) {
    return request({
        method: 'delete',
        url: `/voyages/${voyageID}`,
    });
}

export function editParamsVoyage(voyageID, data = {}) {
    return request({
        method: 'put',
        url: `/voyages/${voyageID}`,
        data,
    });
}

export function updateVoyageRoute(voyageID, fileBinaryString) {
    return request({
        method: 'put',
        url: `/voyages/${voyageID}/planned-route`,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        data: {
            file: fileBinaryString,
        },
    });
}
