import { css } from '@emotion/react';
import styled from '@emotion/styled';

import dt from '@constants/designTokens/index';

const contentStyles = css`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: ${dt.Colors.Typography.Main};
    pointer-events: auto;
    background-color: ${dt.Colors.Background.White};
    background-clip: padding-box;
    border-radius: 0.5rem;
    outline: 0;
    height: 100%;
`;

export default styled('div')(contentStyles);
