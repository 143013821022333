import base from './base';

export default {
    White: base.White,
    Black: base.TrueBlack,
    Gray: base.Gray400,
    Primary: base.Blue,
    Primary600: base.Blue600,
    Primary800: base.Blue800,

    Success: base.Green,

    Green100: base.Green100,
    Green200: base.Green200,
    Green300: base.Green300,

    Green600: base.Green600,
    Green800: base.Green800,

    Danger: base.Red,
    Danger600: base.Red600,
    Danger800: base.Red800,

    Transparent: base.Transparent,
};
