import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Header } from '@ui/index';

const titleStyles = css`
    margin-bottom: 0;
`;

export default styled(Header)(titleStyles);
