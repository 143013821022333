import dayjs from 'dayjs';
import dayjsParse from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';

dayjs.locale('ru');
dayjs.extend(dayjsParse);
dayjs.extend(utc);

export { dayjs };

// methods

const SERVER_FORMAT = 'YYYY-MM-DD';
const SERVER_UTC_LOCAL_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
export const DISPLAY_FORMAT = 'DD.MM.YYYY';

const convert = (string, from, to, placeholder = 'Неверная дата') => {
    let result = dayjs(string, from);
    if (result.isValid()) {
        return result.format(to);
    }
    return placeholder;
};

export const isValid = (date) => dayjs(date).isValid();

// from SERVER_FORMAT
export const normalize = (string, placeholder) => convert(string, SERVER_FORMAT, DISPLAY_FORMAT, placeholder);
export const normalizeDayJS = (dayJSData) => dayJSData.isValid() && dayJSData.format(DISPLAY_FORMAT);
export const normalizeFromUTCLocalFormat = (string, displayFormat = DISPLAY_FORMAT, placeholder) =>
    convert(string, SERVER_UTC_LOCAL_FORMAT, displayFormat, placeholder);

// to SERVER_FORMAT
export const dismantle = (string, placeholder) => convert(string, DISPLAY_FORMAT, SERVER_FORMAT, placeholder);
export const dismantleDayJS = (dayJSData) => dayJSData.isValid() && dayJSData.format(SERVER_FORMAT);

export const dismantleToUTCLocalFormat = (string, displayFormat = DISPLAY_FORMAT, placeholder) =>
    convert(string, displayFormat, SERVER_UTC_LOCAL_FORMAT, placeholder);

// Helpers
export const convertWithSaveUTCTime = (date) => dayjs.utc(date).format('YYYY-MM-DDTHH:mm:ss');
