import React from 'react';

import { TypographySize } from '@ui/index';

import StyledWrapper from './styled/vector/Wrapper';
import StyledArrow from './styled/vector/Arrow';
import StyledTitle from './styled/vector/Title';

const Vector = ({ title }) => {
    return (
        <StyledWrapper>
            <StyledArrow />
            {title && <StyledTitle variant={TypographySize.LARGE}>{title}</StyledTitle>}
        </StyledWrapper>
    );
};

export default Vector;
