import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Button } from '@ui/index';

const itemStyles = css`
    height: 32px;
    line-height: 16px;
`;

export default styled(Button)(itemStyles);
