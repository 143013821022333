import React, { useMemo, useCallback, memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { dayjs, convertWithSaveUTCTime } from '@functions/datetime';

import { ROUTES_DYNAMIC } from '@/routes';

import { Table } from '@ui/index';

import StyledTr from './styled/Tr';

const VoyagesList = ({ voyages }) => {
    const navigate = useNavigate();

    const theadNode = useMemo(() => {
        return (
            <thead>
                <tr>
                    <th>Vessel name</th>
                    <th>Route</th>
                    <th>Created At</th>
                    <th>ETA</th>
                    <th>Status</th>
                </tr>
            </thead>
        );
    }, []);

    const tbodyTrNode = useCallback(
        (voyage) => {
            return (
                <StyledTr
                    key={voyage.voyage_id}
                    onClick={() => navigate(ROUTES_DYNAMIC.VOYAGES_EDIT(voyage.voyage_id))}
                >
                    <td style={{ width: '10%' }}>{voyage.vessel.name}</td>
                    <td style={{ width: '20%' }}>{(voyage.route_name || '-').trim()}</td>
                    <td style={{ width: '10%' }}>
                        {dayjs(convertWithSaveUTCTime(voyage.created_at)).format('DD.MM.YYYY HH:mm')}
                    </td>
                    <td style={{ width: '10%' }}>
                        {voyage.eta ? (
                            dayjs(convertWithSaveUTCTime(voyage.eta)).format('DD.MM.YYYY HH:mm')
                        ) : (
                            '-'
                        )}
                    </td>
                    <td style={{ width: '8%' }}>{voyage.status}</td>
                </StyledTr>
            );
        },
        [ navigate ]
    );

    const tbodyNode = useMemo(
        () => {
            return (
                <tbody>
                    {voyages.map((voyage) => (
                        <React.Fragment key={voyage.voyage_id}>{tbodyTrNode(voyage)}</React.Fragment>
                    ))}
                </tbody>
            );
        },
        [ voyages, tbodyTrNode ]
    );

    return <Table theadNode={theadNode} tbodyNode={tbodyNode} />;
};

export default memo(VoyagesList);
