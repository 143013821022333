import request from '@api';

export function getEmission(voyageID) {
    return request({
        method: 'get',
        url: `voyages/${voyageID}/dashboards/emission`,
    });
}

export function getPower(voyageID) {
    return request({
        method: 'get',
        url: `voyages/${voyageID}/dashboards/power`,
    });
}

export function getSpeed(voyageID) {
    return request({
        method: 'get',
        url: `voyages/${voyageID}/dashboards/speed`,
    });
}
