import { css } from '@emotion/react';
import styled from '@emotion/styled';

import dt from '@constants/designTokens';

const titleStyles = css`
    font-weight: 500;
    font-size: 1.2rem;
    color: ${dt.Colors.Typography.Main};
`;

export default styled('div')(titleStyles);
