import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Cookie from 'js-cookie';

import { ROUTES } from '@/routes';
import { Divider, Dropdown } from '@ui/index';
import { UserDetailHeader } from '@components/index';

import { useAuth } from '@hooks/index';
import CONFIG from '@config';

import StyledUserDetailItem from './styled/UserDetailItem';

import { getUser } from './api';

const UserDropdown = () => {
    const queryClient = useQueryClient();
    const { userID } = useAuth();
    const { data: { data: userDetails } = {} } = useQuery({
        queryKey: `get-user/${userID}`,
        queryFn: () => getUser(userID),
        refetchInterval: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });

    const navigate = useNavigate();
    const onLogout = () => {
        Cookie.remove(CONFIG.TOKEN_NAME, { path: '' });
        queryClient.removeQueries();
        navigate(ROUTES.AUTH);
    };

    return (
        <Dropdown
            wrapperClassName='no-caret d-inline-block'
            toggleClassName='btn-icon btn-transparent-dark'
            toggleNode={<img className='img-fluid' alt='Profile' src='/profile.png' />}
            contentNode={
                <React.Fragment>
                    <UserDetailHeader details={userDetails} />

                    <Divider />

                    <StyledUserDetailItem icon='log-out' title='Logout' onClick={onLogout} />
                </React.Fragment>
            }
        />
    );
};

export default UserDropdown;
