import React, { memo, useCallback } from 'react';
import { useQueries } from 'react-query';

import { VesselCheckpointsPath, AzipodGauge } from '@modules/index';
import { Header } from '@ui/index';

import StyledWrapper from './styled/Wrapper';
import StyledCheckpointsWrapper from './styled/CheckpointsWrapper';
import StyledAzipodsWrapper from './styled/AzipodsWrapper';

import { getEnginesPower, getEnginesSuggestedPower } from './api';

const MIN_POWER = 0;
const MAX_POWER = 15;
const AZIPOD_TYPE = {
    PORT: 'port',
    CENTER: 'center',
    STBD: 'stbd',
};

const AZIPOD_TITLE = {
    [AZIPOD_TYPE.PORT]: 'Port',
    [AZIPOD_TYPE.CENTER]: 'Center',
    [AZIPOD_TYPE.STBD]: 'Stbd',
};

const VesselGauges = ({ voyageID, vessel }) => {
    const { vessel_id: vesselID } = vessel;

    const [ currentPower, suggestedPower ] = useQueries([
        {
            queryKey: `engines-power/${vesselID}`,
            queryFn: () => getEnginesPower(vesselID),
        },
        {
            queryKey: `suggested-engines-power/${voyageID}`,
            queryFn: () => getEnginesSuggestedPower(voyageID),
        },
    ]);

    const { data: { data: currentPowerData } = {}, isLoading: isLoadingCurrentPower } = currentPower;
    const { data: { data: suggestedPowerData } = {}, isLoading: isLoadingSuggestedPower } = suggestedPower;

    const renderAzipodIndicator = useCallback(
        (azipodType) => {
            if (Object.keys(suggestedPowerData).length === 0) {
                return null;
            }

            return (
                <div className='d-flex flex-column align-items-center px-2 flex-grow-1'>
                    <Header>Azipod {AZIPOD_TITLE[azipodType]}</Header>
                    <AzipodGauge
                        id={`azipod-${AZIPOD_TITLE[azipodType]}`}
                        min={MIN_POWER}
                        max={MAX_POWER}
                        value={currentPowerData[azipodType]}
                        suggestedValue={suggestedPowerData[azipodType]}
                        suffix='MW'
                    />
                </div>
            );
        },
        [ currentPowerData, suggestedPowerData ]
    );

    if (isLoadingCurrentPower || isLoadingSuggestedPower) {
        return null;
    }

    return (
        <React.Fragment>
            <StyledWrapper>
                <StyledCheckpointsWrapper>
                    <VesselCheckpointsPath voyageID={voyageID} />
                </StyledCheckpointsWrapper>
                <StyledAzipodsWrapper>
                    {renderAzipodIndicator(AZIPOD_TYPE.PORT)}
                    {renderAzipodIndicator(AZIPOD_TYPE.CENTER)}
                    {renderAzipodIndicator(AZIPOD_TYPE.STBD)}
                </StyledAzipodsWrapper>
            </StyledWrapper>
        </React.Fragment>
    );
};

export default memo(VesselGauges);
