import { css } from '@emotion/react';
import styled from '@emotion/styled';

const wrapperStyles = css`
    display: block;
    min-height: 1.5rem;
    margin-bottom: 0.125rem;
    position: relative;
`;

export default styled('label')(wrapperStyles);
