import React from 'react';
import { Link } from 'react-router-dom';

import { Modal, TextSize, ModalVariant, Button } from '@ui/index';

import StyledWrapper from './styled/Wrapper';
import StyledCode from './styled/Code';
import StyledParagraph from './styled/Message';

const ServerError = ({ code, message }) => {
    return (
        <Modal title='Server error' isOpen={true} variant={ModalVariant.LARGE_SIZE}>
            <StyledWrapper>
                <StyledCode variant={TextSize.EXTRA_LARGE}>{code}</StyledCode>
                <StyledParagraph variant={TextSize.LARGE}>{message}</StyledParagraph>
                <br />
                <Button onClick={() => window.location.reload()}>Reload Page</Button>

                <br />

                <Link to='/'>Home page</Link>
            </StyledWrapper>
        </Modal>
    );
};

export default ServerError;
