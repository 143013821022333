import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Paragraph } from '@ui/index';

const pointTimeStyles = css`
    position: absolute;
    top: calc(100% + 30px);
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
`;

export default styled(Paragraph)(pointTimeStyles);
