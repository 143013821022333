import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

const backdropStyles = css`
    background: transparent;
    padding: 1rem;
    transition: background 0.3s;
`;

export default styled('div')(backdropStyles);
