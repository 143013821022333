import React, { isValidElement } from 'react';
import { createPortal } from 'react-dom';
import { useClickAway } from '@uidotdev/usehooks';

import { Icon, ButtonVariant, IconSize, HeadingTag } from '@ui/index';

import StyledBackdrop from './styled/Backdrop';
import StyledModal from './styled/Modal';
import StyledDialog from './styled/Dialog';
import StyledContent from './styled/Content';
import StyledClose from './styled/Close';
import StyledTitle from './styled/Title';

import StyledContentHeader from './styled/content/Header';
import StyledContentBody from './styled/content/Body';

const ModalRoot = React.forwardRef(({ title = '', children, variant, onClose }, ref) => {
    return (
        <StyledModal>
            <StyledDialog variant={variant} ref={ref}>
                <StyledContent>
                    <StyledContentHeader>
                        {isValidElement(title) ? (
                            title
                        ) : (
                            <StyledTitle tag={HeadingTag.H3}>{title}</StyledTitle>
                        )}
                        {onClose && (
                            <StyledClose variant={ButtonVariant.LINK} onClick={onClose}>
                                <Icon size={IconSize.AUTO} icon='x' />
                            </StyledClose>
                        )}
                    </StyledContentHeader>
                    <StyledContentBody>{children}</StyledContentBody>
                </StyledContent>
            </StyledDialog>
        </StyledModal>
    );
});

const Modal = ({ title = '', children, isOpen, variant, onClose }) => {
    const portalParent = document.body;
    const wrapperModalRef = useClickAway(() => {
        onClose && onClose();
    });

    if (!isOpen) {
        return null;
    }

    return createPortal(
        <React.Fragment>
            <StyledBackdrop isOpen={isOpen} />
            <ModalRoot title={title} ref={wrapperModalRef} variant={variant} onClose={onClose}>
                {children}
            </ModalRoot>
        </React.Fragment>,
        portalParent
    );
};

export default Modal;
