import { css } from '@emotion/react';
import styled from '@emotion/styled';

import dt from '@constants/designTokens';

const wrapperStyles = css`
    position: absolute;
    top: 0px;
    left: 70px;
    z-index: 100;

    border-radius: 10px;
    background: ${dt.Colors.Background.Black};
    padding: 20px;
`;

export default styled('div')(wrapperStyles);
