import React, { useRef, useCallback, useLayoutEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

import dt from '@constants/designTokens';

import { dayjs, convertWithSaveUTCTime } from '@functions/datetime';

const LineCollection = ({ id, collectionLines }) => {
    let chartRef = useRef(null);
    let rootRef = useRef(null);
    let legendRef = useRef(null);

    let seriesRef = useRef([]);

    const getLineData = useCallback((items) => {
        return items.map(({ date, value }) => ({
            date: dayjs(convertWithSaveUTCTime(date)).valueOf(),
            value,
        }));
    }, []);

    useLayoutEffect(
        () => {
            rootRef.current = am5.Root.new(id);

            const chartTheme = am5.Theme.new(rootRef.current);

            // Move minor label a bit down
            chartTheme.rule('AxisLabel', [ 'minor' ]).setAll({
                dy: 1,
            });

            // Tweak minor grid opacity
            chartTheme.rule('Grid', [ 'minor' ]).setAll({
                strokeOpacity: 0.08,
            });

            // Set themes
            // https://www.amcharts.com/docs/v5/concepts/themes/
            rootRef.current.setThemes([ am5themes_Animated.new(rootRef.current), chartTheme ]);

            // Create chart
            // https://www.amcharts.com/docs/v5/charts/xy-chart/
            chartRef.current = rootRef.current.container.children.push(
                am5xy.XYChart.new(rootRef.current, {
                    panX: false,
                    panY: false,
                    wheelX: 'panX',
                    wheelY: 'zoomX',
                    paddingLeft: 0,
                    paddingBottom: 30,
                })
            );

            // Add cursor
            // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
            const cursor = chartRef.current.set(
                'cursor',
                am5xy.XYCursor.new(rootRef.current, {
                    behavior: 'zoomX',
                })
            );
            cursor.lineY.set('visible', false);

            return () => {
                rootRef.current.dispose();
                chartRef.current.dispose();

                cursor.dispose();
            };
        },
        [ rootRef, chartRef, id ]
    );

    useLayoutEffect(
        () => {
            // Create axes
            // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
            const xAxis = chartRef.current.xAxes.push(
                am5xy.DateAxis.new(rootRef.current, {
                    maxDeviation: 0.1,
                    groupData: false,
                    baseInterval: {
                        timeUnit: 'minute',
                        count: 5,
                    },
                    renderer: am5xy.AxisRendererX.new(rootRef.current, {
                        minorGridEnabled: true,
                        minGridDistance: 300,
                        minorLabelsEnabled: true,
                    }),
                    tooltip: am5.Tooltip.new(rootRef.current, {}),
                })
            );

            xAxis.set('minorDateFormats', {
                hour: 'hh',
            });

            const yRenderer = am5xy.AxisRendererY.new(rootRef.current, {});

            const yAxis = chartRef.current.yAxes.push(
                am5xy.ValueAxis.new(rootRef.current, {
                    renderer: yRenderer,
                })
            );

            function generateSeries (name, data, options = {}) {
                const { series: seriesOptions = {} } = options;

                // Add series
                // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
                const series = chartRef.current.series.push(
                    am5xy.LineSeries.new(rootRef.current, {
                        name,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: 'value',
                        valueXField: 'date',
                        stroke: seriesOptions.fill || dt.Colors.Border.Primary,
                        tooltip: am5.Tooltip.new(rootRef.current, {
                            labelText: '{valueY}',
                        }),
                    })
                );

                series.strokes.template.setAll({
                    strokeWidth: 1.3,
                });

                // Actual bullet
                series.bullets.push(function () {
                    const bulletCircle = am5.Circle.new(rootRef.current, {
                        radius: 0,
                        fill: seriesOptions.fill || series.get('fill'),
                    });

                    return am5.Bullet.new(rootRef.current, {
                        sprite: bulletCircle,
                    });
                });

                series.data.setAll(data);
                series.appear(1000);

                return series;
            }

            // Add scrollbar
            // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
            chartRef.current.set(
                'scrollbarX',
                am5.Scrollbar.new(rootRef.current, {
                    orientation: 'horizontal',
                })
            );

            legendRef.current = chartRef.current.children.push(
                am5.Legend.new(rootRef.current, {
                    centerX: am5.p50,
                    x: am5.p50,
                    y: am5.p100,
                })
            );

            // Make series change state when legend item is hovered
            legendRef.current.itemContainers.template.states.create('hover', {});

            legendRef.current.itemContainers.template.events.on('pointerover', function (e) {
                e.target.dataItem.dataContext.hover();
            });

            legendRef.current.itemContainers.template.events.on('pointerout', function (e) {
                e.target.dataItem.dataContext.unhover();
            });

            collectionLines.forEach(({ label, points, options = {} }) => {
                seriesRef.current.push(generateSeries(label, getLineData(points), options));
            });

            legendRef.current.data.setAll(chartRef.current.series.values);

            chartRef.current.appear(1000, 100);

            return () => {
                seriesRef.current.forEach((series) => series.dispose());
                seriesRef.current = [];

                legendRef.current.dispose();
                legendRef.current = [];

                yAxis.dispose();
                xAxis.dispose();
            };
        },
        [ collectionLines, chartRef, rootRef, seriesRef, legendRef, getLineData ]
    );

    return <div id={id} style={{ height: '100%' }} />;
};

export default LineCollection;
