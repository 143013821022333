import React, { useState } from 'react';
import { useToggle } from '@uidotdev/usehooks';

import { Modal, Button, ButtonVariant } from '@ui/index';
import { InputField } from '@components/index';
import { VoyagerImportFile } from '@modules/index';

import { createVoyage } from './api';

const VoyagesTools = ({ onRefetchVoyages, onFilterByRoute }) => {
    const [filterValue, onChange] = useState('');
    const [isOpenFileModal, toggleStateFileModal] = useToggle(false);

    return (
        <div className='d-flex'>
            <div className='col-5'>
                <InputField
                    placeholder='Search voyager by number or name'
                    value={filterValue}
                    onChange={(event) => {
                        const value = String(event.target.value);
                        onChange(value);
                        onFilterByRoute(value.toLowerCase());
                    }}
                />
            </div>
            <Button className='ms-auto' variant={ButtonVariant.PRIMARY} onClick={() => toggleStateFileModal()}>
                Create New Voyage
            </Button>
            <Modal
                title='Import file for create voyage'
                isOpen={isOpenFileModal}
                onClose={toggleStateFileModal}
            >
                <VoyagerImportFile
                    actionByImport={createVoyage}
                    onSuccess={onRefetchVoyages}
                    onClose={toggleStateFileModal}
                />
            </Modal>
        </div>
    );
};

export default VoyagesTools;
