import { css } from '@emotion/react';

import dt from '@constants/designTokens';

export const getInfoStyles = () => css`
    background: ${dt.Colors.Background.Green100};
    color: ${dt.Colors.Typography.White};
    border-color: ${dt.Colors.Border.Green100};

    &:hover {
        background: ${dt.Colors.Background.Green200};
        color: ${dt.Colors.Typography.White};
    }

    &:active {
        background: ${dt.Colors.Background.Green300} !important;
        color: ${dt.Colors.Typography.White} !important;
    }
`;
