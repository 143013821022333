import React, { memo } from 'react';
import styled from '@emotion/styled';

import StyledIcon from './styled/Icon';

const Icon = ({ size, icon, ...restProps }) => {
    return <StyledIcon sizevariant={size} icon={icon} {...restProps} />;
};

export default styled(memo(Icon))();
